import React from "react";
import "./App.css";
import Main from "./screens/Main";
import CssBaseline from "@mui/material/CssBaseline";

class App extends React.Component {
	render() {
		return (
			<React.Fragment>
				<CssBaseline />
				<Main />
			</React.Fragment>
		);
	}
}

export default App;
