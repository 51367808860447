import { responsiveFontSizes } from "@mui/material"
import AuthApi from "../api/bo/AuthApi"
import { UserRole } from "../api/bo/models/auth/UserRole"
import UserApi from "../api/bo/UserApi"

export default class UserService {
	public static getAll() {
		return UserApi.getAll().then(items => {
			return items
		})
	}
	
	public static get(userId: string) {
		return UserApi.getOne(userId).then(item => {
			return item
		})
	}
	
	public static create(username: string, password: string, role: UserRole) {
		return UserApi.create(username, password, role).then(success => {
			return success
		})
	}
}
