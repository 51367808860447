import { InputLabel } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import React, { ReactNode } from "react"

interface InputWrapperProps {
	id?: string
	label?: string
	description?: string
	style?: object
	children: ReactNode
}

interface InputWrapperState {
}

class InputWrapper extends React.Component<InputWrapperProps, InputWrapperState> {
	constructor(props: InputWrapperProps) {
		super(props)
	}

	render() {
		const {
			id,
			label,
			description,
			children,
			style,
		} = this.props

		return (
			<FormControl
				fullWidth
				style={{
					...style
				}}
			>
				{
					label !== undefined && id !== undefined
						? <InputLabel
							id={"label-" + id}
						>
							{label}
						</InputLabel>
						: null
				}
				{children}
				{
					description
						? <FormHelperText
							style={{
								marginLeft: 0,
							}}
						>
							{description}
						</FormHelperText>
						: null
				}
			</FormControl>
		)
	}
}

export default InputWrapper
