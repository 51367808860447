import React from "react"
import {
	FormGroup,
} from "@mui/material"
import Typer from "../../../components/form/Typer"
import moment from "moment"
import dayjs, { Dayjs } from "dayjs"
import ICaseRequest from "../../../api/bo/models/case/ICaseRequest"
import CaseService from "../../../services/CaseService"
import Dater from "../../../components/form/Dater"

interface ManageCaseProps {
	identifier: string
	dateOfBirth?: Date
	setIdentifier: (val: string) => void
	setDateOfBirth: (val?: Date) => void
}

interface ManageCaseState {
	isIdentifierValid: boolean
	isDobValid: boolean
	error?: string
}

class ManageCase extends React.Component<ManageCaseProps, ManageCaseState> {
	constructor(props: ManageCaseProps) {
		super(props)

		this.state = {
			isIdentifierValid: false,
			isDobValid: false,
		}
	}

	componentDidMount(): void {
		
	}

	setIdentifier = (identifier: string) => {
		const {
			setIdentifier,
		} = this.props

		setIdentifier(identifier)
	}

	render() {
		const {
			identifier,
			dateOfBirth,
			setDateOfBirth,
		} = this.props
		const {
			error,
		} = this.state

		return (
			<FormGroup>
				{/* <Collapse in={error.length !> 0}>
					<Alert severity="error">{error}</Alert>
				</Collapse> */}
				<Typer
					label="Etikett"
					value={identifier}
					required
					min={4}
					max={40}
					onTyped={this.setIdentifier}
					description="Ange valfri etikett. Används för att du lättare ska kunna särskilja olika ärenden."
				/>
				<Typer
					label="Födelsedatum"
					value={dateOfBirth ? moment.utc(dateOfBirth).format("YYYY-MM-DD") : ""}
					type="date"
					onTyped={(v, c) => {
						setDateOfBirth(moment.utc(v).toDate())
					}}
				/>
				{/* <Dater
					label="Födelsedatum"
					value={dateOfBirth}
					type="date"
					onChange={setDateOfBirth}
				/> */}
			</FormGroup>
		)
	}
}

export default ManageCase
