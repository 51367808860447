import React from "react"
import IQuestionnaire from "../../../../api/bo/models/questionnaire/IQuestionnaire"
import ICalculationPart from "../../../../api/bo/models/questionnaire/ICalculationPart"
import ICalculationPartCutOff from "../../../../api/bo/models/questionnaire/ICalculationPartCutOff"
import CalculationMethod from "../../../../api/bo/models/questionnaire/CalculationMethod"
import Typer from "../../../../components/form/Typer"
import Selecter, { ISelecterOption } from "../../../../components/form/Selecter"
import QuestionType from "../../../../api/bo/models/questionnaire/QuestionType"
import IReceiverType from "../../../../api/bo/models/receivertype/IReceiverType"

interface ManagePartProps {
	questionnaire?: IQuestionnaire
	receiverTypes: IReceiverType[]
	part: ICalculationPart
	updatePart: (part: ICalculationPart) => void
}

interface ManagePartState {
	identifier: string
	tempCutOffs: ICalculationPartCutOff[],
	updateTimer?: ReturnType<typeof setTimeout>
}

class ManagePart extends React.Component<ManagePartProps, ManagePartState> {
	constructor(props: ManagePartProps) {
		super(props)

		const {
			part,
		} = this.props

		this.state = {
			identifier: part.identifier,
			tempCutOffs: part.cutOffs,
			updateTimer: undefined,
		}
	}

	savePart = () => {
		const {
			identifier,
			tempCutOffs,
		} = this.state
		const {
			part,
			updatePart,
		} = this.props

		updatePart({
			...part,
			identifier,
			cutOffs: tempCutOffs.filter(c => c.amount !== undefined),
		})
	}

	handleUpdate = () => {
		const {
			updateTimer,
		} = this.state

		if (updateTimer !== undefined) {
			clearTimeout(updateTimer)
		}

		this.setState({
			updateTimer: setTimeout(this.savePart, 2000)
		})
	}

	setIdentifier = (identifier: string) => {
		this.setState({
			identifier,
		}, this.handleUpdate)
	}

	setCutOff = (amount: string, receiverTypeId?: string) => {
		const {
			tempCutOffs,
		} = this.state
		
		let newCutOffs = [...tempCutOffs]
		let existingIndex = newCutOffs.findIndex(c => c.receiverTypeId == receiverTypeId)

		try {
			const v = parseFloat(amount)

			if (!amount || isNaN(v)) {
				throw ""
			}

			if (existingIndex < 0) {
				newCutOffs.push({
					receiverTypeId,
					amount: v,
				} as ICalculationPartCutOff)
			}
			else {
				newCutOffs[existingIndex].amount = v
			}
		}
		catch (e) {
			if (existingIndex >= 0) {
				newCutOffs.splice(existingIndex, 1)
			}
		}

		this.setState({
			tempCutOffs: newCutOffs,
		}, this.handleUpdate)
	}

	renderIdentifierInput = () => {
		const {
			identifier,
		} = this.state

		return <Typer
			label="Etikett"
			type="text"
			value={identifier}
			required
			onTyped={(val) => {
				this.setIdentifier(val)
			}}
			description={"Etikett för att hålla reda på."}
		/>
	}

	renderQuestionsPicker = () => {
		const {
			questionnaire,
			part,
			updatePart,
		} = this.props

		let options: ISelecterOption[] = []
		
		if (questionnaire !== undefined) {
			options = questionnaire.questions
				.filter(q => q.type != QuestionType.FreeText)
				.map(q => {
					return {
						label: "#" + q.order,
						key: "question-" + q.order,
						value: q.questionId!,
					}
				})
		}

		return <Selecter
			label="Frågor"
			required
			multiple
			value={part.questionIds}
			options={options}
			onSelect={(val) => {
				updatePart({
					...part,
					questionIds: val as string[],
				})
			}}
			description="De frågor som ska behandlas av beräkningen."
		/>
	}

	renderMethodPicker = () => {
		const {
			part,
			updatePart,
		} = this.props

		let options = Object
			.keys(CalculationMethod)
			.filter(key => isNaN(Number(key)))
			.map(key => {
			const v = CalculationMethod[key as keyof typeof CalculationMethod]

			return {
				label: key,
				key: "method-" + key,
				value: v,
				disabled: v === part.method,
			}
		})

		return <Selecter
			label="Kalkyleringsmetod"
			value={part.method}
			required
			options={options}
			onSelect={(val) => {
				updatePart({
					...part,
					method: CalculationMethod[val as CalculationMethod],
				})
			}}
			description="Vilken modell som ska appliceras till denna regel."
		/>
	}

	renderReceiverType = (receiverType: { receiverTypeId?: string, identifier: string }) => {
		const {
			tempCutOffs,
		} = this.state

		const existing = tempCutOffs.find(c => c.receiverTypeId == receiverType.receiverTypeId)

		return <Typer
			label={receiverType.identifier}
			key={receiverType.receiverTypeId}
			type="number"
			value={existing ? existing.amount : undefined}
			onTyped={(val) => {
				this.setCutOff(val, receiverType.receiverTypeId)
			}}
			description={"Gränsvärde för " + receiverType.identifier + "."}
		/>
	};

	render() {
		const {
			receiverTypes,
		} = this.props

		return (
			<>
			{
				this.renderIdentifierInput()
			}
			{
				this.renderQuestionsPicker()
			}
			{
				this.renderMethodPicker()
			}
			{
				receiverTypes.map((item, index) => {
					return this.renderReceiverType(item)
				})
			}
			{
				this.renderReceiverType({ receiverTypeId: undefined, identifier: "övriga" })
			}
			</>
		)
	}
}

export default ManagePart
