import React from "react"
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef"
import ISimpleQuestionnaire from "../../../api/bo/models/questionnaire/ISimpleQuestionnaire"
import ATable from "../../../components/ATable"

const columns = [
	// {
	// 	field: "questionnaireId",
	// 	title: "ID",
	// },
	{
		field: "identifier",
		headerName: "ID",
		flex: 1,
	},
	{
		field: "displayName",
		headerName: "Etikett",
		flex: 2,
	},
	{
		field: "isLocked",
		headerName: "Låst",
		type: "boolean",
		flex: 0.5,
	},
	// {
	// 	field: "createdAt",
	// 	headerName: "Skapad",
	// 	type: "datetime",
	// },
] as GridColDef<ISimpleQuestionnaire>[]

interface QuestionnaireListProps {
	questionnaires: ISimpleQuestionnaire[]
	onSelectionChange: (selectedQuestionnaires: ISimpleQuestionnaire[]) => void
	selectedIds: string[]
}

interface QuestionnaireListState {
}

class QuestionnaireList extends React.Component<QuestionnaireListProps, QuestionnaireListState> {
	constructor(props: QuestionnaireListProps) {
		super(props)

		this.state = {
		}
	}

	render() {
		const {
			questionnaires,
			onSelectionChange,
			selectedIds,
		} = this.props
		// const rows = cases
		// 	// .filter(f => { if (showClosed || f.status !== CASE_STATUS.CLOSED) { return f } })
		// 	.map(f => {
		// 		return f
		// 	})

		return (
			<ATable
				columns={columns}
				rows={questionnaires}
				onSelectionChange={onSelectionChange}
				uniqueKey="questionnaireId"
				selectedKeys={selectedIds}
			/>
		)
	}
}

export default QuestionnaireList