import React from "react"
import {
	Button,
} from "@mui/material"

import { FormLayout } from "./FormLayout"

import SinglePage from "./SinglePage"
import Stepping from "./Stepping"

import IClientQuestion from "../../../api/client/models/IClientQuestion"
import IClientQuestionnaire from "../../../api/client/models/IClientQuestionnaire"
import IClientSubmitAnswer from "../../../api/client/models/IClientSubmitAnswer"

interface QuestionnaireProps {
	questionnaire: IClientQuestionnaire
	layout?: FormLayout
	providedAnswers: IClientSubmitAnswer[]
	setProvidedAnswers: (answers: IClientSubmitAnswer[]) => void
	onSubmit: () => void
	setActiveQuestion?: (question: IClientQuestion) => void
}

interface QuestionnaireState {
	activeQuestionId?: string
	isSubmitDialogOpen: boolean
}

class Questionnaire extends React.Component<QuestionnaireProps, QuestionnaireState> {
	constructor(props: QuestionnaireProps) {
		super(props)

		const {
			questionnaire,
		} = this.props

		this.state = {
			activeQuestionId: questionnaire.questions[0]?.questionId,
			isSubmitDialogOpen: false,
		}
	}

	setActiveQuestionId = (id: string) => {
		const {
			questionnaire,
			setActiveQuestion,
		} = this.props

		const question = questionnaire.questions.find(q => q.questionId === id)

		this.setState({
			activeQuestionId: id,
		}, setActiveQuestion !== undefined
			&& question !== undefined
			? () => {
				setActiveQuestion(question)
			}
			: undefined
		)
	}

	getProvidedAnswer = (questionId: string) => {
		const {
			providedAnswers,
		} = this.props

		return providedAnswers.find(a => a.questionId === questionId)
	}

	isQuestionAnswered = (questionId: string) => {
		const providedAnswer = this.getProvidedAnswer(questionId)

		return providedAnswer !== undefined
			&& (providedAnswer.answerId !== undefined
				|| providedAnswer.text !== undefined)
	}

	setAnswer = (answer: IClientSubmitAnswer) => {
		const {
			providedAnswers,
			setProvidedAnswers,
		} = this.props

		const updatedProvidedAnswers = providedAnswers.map(a => {
			if (a.questionId === answer.questionId) {
				return answer
			}
			return a
		})

		if (!providedAnswers.some(a => a.questionId === answer.questionId)) {
			updatedProvidedAnswers.push(answer)
		}

		setProvidedAnswers(updatedProvidedAnswers)
	}

	pickAnswer = (questionId: string, answerId: string) => {
		this.setAnswer({
			questionId,
			answerId,
		})
	}

	setFreeTextAnswer = (questionId: string, text: string) => {
		this.setAnswer({
			questionId,
			text,
		})
	}

	areAllQuestionsAnswered = () => {
		const {
			questionnaire,
			providedAnswers,
		} = this.props
		
		return providedAnswers.length === questionnaire.questions.length
	}

	getAllQuestionsWithProvidedAnswer = () => {
		const {
			questionnaire,
		} = this.props

		const questionsWithProvidedAnswer: { question: IClientQuestion, answer: IClientSubmitAnswer }[] = []

		for (var i in questionnaire.questions) {
			const question = questionnaire.questions[i]
			const answer = this.getProvidedAnswer(question.questionId)

			if (answer === undefined) {
				continue
			}

			questionsWithProvidedAnswer.push({
				question,
				answer,
			})
		}
		
		return questionsWithProvidedAnswer
	}

	submit = () => {
		const {
			onSubmit,
		} = this.props

		if (this.areAllQuestionsAnswered()) {
			onSubmit()
		}
	}

	closeSubmitDialog = () => {
		this.setState({
			isSubmitDialogOpen: false
		})
	}

	renderQuestionnaire = () => {
		const {
			questionnaire,
			layout,
		} = this.props
		const {
			activeQuestionId,
		} = this.state

		switch (layout) {
			case FormLayout.Stepping:
				return <Stepping
					questions={questionnaire.questions}
					pickAnswer={this.pickAnswer}
					setFreeTextAnswer={this.setFreeTextAnswer}
					getProvidedAnswer={this.getProvidedAnswer}
					activeQuestionId={activeQuestionId}
					setActiveQuestionId={this.setActiveQuestionId}
				/>
			// case FormLayout.SinglePage:
			// default:
			// 	return <SinglePage
			// 		questions={questionnaire.questions}
			// 		pickAnswer={this.pickAnswer}
			// 		setFreeTextAnswer={this.setFreeTextAnswer}
			// 		getProvidedAnswerByQuestionId={this.getProvidedAnswer}
			// 	/>
		}
	}

	renderSubmit = () => {
		return <div
			style={{
				margin: "25.5px auto 17px",
			}}
		>
			<Button
				fullWidth
				size="large"
				color="primary"
				variant="contained"
				onClick={this.submit}
				disabled={!this.areAllQuestionsAnswered()}
				// startIcon={<SaveRounded />}
			>
				Skicka in
			</Button>
		</div>
	}

	// renderResultDialog = () => {
	// 	const {
	// 		isSubmitDialogOpen
	// 	} = this.state
	// 	const questionsWithProvidedAnswers = this.getAllQuestionsWithProvidedAnswer()

	// 	return <Dialog
	// 		open={isSubmitDialogOpen}
	// 		onClose={this.closeSubmitDialog}
	// 		aria-labelledby="dialog-result-title"
	// 		aria-describedby="dialog-result-description"
	// 	>
	// 		<DialogTitle
	// 			id="dialog-result-title"
	// 		>
	// 			Här är dina svar
	// 		</DialogTitle>
	// 		<DialogContent>
	// 			<TableContainer>
	// 				<Table
	// 					aria-label="simple table"
	// 				>
	// 					<TableHead>
	// 						<TableRow>
	// 							<TableCell>Fråga</TableCell>
	// 							<TableCell>Svar</TableCell>
	// 						</TableRow>
	// 					</TableHead>
	// 					<TableBody>
	// 					{
	// 						questionsWithProvidedAnswers.map((qa) => (
	// 							<TableRow key={"question-" + qa.question.questionId}>
	// 								<TableCell
	// 									component="th"
	// 								>
	// 									{"#" + qa.question.order + ": " + qa.question.text}
	// 								</TableCell>
	// 								<TableCell>
	// 									{
	// 										qa.answer !== undefined
	// 										? qa.answer.value + ": " + qa.answer.text
	// 										: "-"
	// 									}
	// 								</TableCell>
	// 							</TableRow>
	// 						))
	// 					}
	// 					</TableBody>
	// 				</Table>
	// 			</TableContainer>
	// 		</DialogContent>
	// 		<DialogActions>
	// 			<Button
	// 				onClick={this.closeSubmitDialog}
	// 				color="primary"
	// 			>
	// 				Stäng
	// 			</Button>
	// 		</DialogActions>
	// 	</Dialog>
	// }

	render() {
		// const {
		// 	isSubmitDialogOpen,
		// } = this.state

		return (
			<main
				style={{
					position: "relative",
					display: "flex",
					// flex: "1",
					flexDirection: "column",
					// margin: "0",
				}}
			>
				{
					this.renderQuestionnaire()
				}
				{
					this.renderSubmit()
				}
				{/* {
					isSubmitDialogOpen
						&& this.renderResultDialog()
				} */}
			</main>
		)
	}
}

export default Questionnaire
