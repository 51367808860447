import React from "react"
import ManageReceiverType from "./ManageReceiverType"
import IReceiverType from "../../../api/bo/models/receivertype/IReceiverType"
import ReceiverTypeService from "../../../services/ReceiverTypeService"
import APopForm from "../../../components/APopForm"

interface CreateReceiverTypeProps {
	onCreated: (id: string) => void
	onCancel: () => void
	showDialog: boolean
}
interface CreateReceiverTypeState {
	item: IReceiverType
	error: string
}

class CreateReceiverType extends React.Component<CreateReceiverTypeProps, CreateReceiverTypeState> {
	constructor(props: CreateReceiverTypeProps) {
		super(props)

		this.state = {
			item: this.generateCleanReceiverType(),
			error: "",
		}
	}

	create = () => {
		const {
			item,
		} = this.state
		const {
			onCreated,
		} = this.props

		if (!item.identifier.length) {
			this.setState({
				error: "Vänligen, ange etikett för att skapa",
			})

			return
		}

		ReceiverTypeService.create(
			item.identifier,
		).then(id => {
			if (id != undefined) {
				this.clear()

				onCreated(id)
			}
		})
	}

	clear = () => {
		this.setState({
			item: this.generateCleanReceiverType(),
		})
	}

	generateCleanReceiverType = () => {
		return {
			identifier: "",
		} as IReceiverType
	}

	cancel = () => {
		const {
			onCancel,
		} = this.props

		this.clear()

		if (onCancel !== undefined) {
			onCancel()
		}
	}

	render() {
		const {
			item,
			error,
		} = this.state
		const {
			showDialog,
		} = this.props

		return (
			<APopForm
				title="Skapa mottagartyp"
				show={showDialog}
				onSubmit={this.create}
				onClose={this.cancel}
			>
				<ManageReceiverType
					updateReceiverType={(item) => {
						this.setState({
							item,
						})
					}}
					item={item}
				/>
			</APopForm>
		)
	}
}

export default CreateReceiverType
