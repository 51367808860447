import React, { ReactNode } from "react"
import { ButtonGroup, Button } from "@mui/material"

export interface IAButtonProps {
	label: string
	action?: (e: React.MouseEvent) => void
	disabled?: boolean
	hidden?: boolean
	group?: any
	type?: "default"
		| "submit"
		| "confirm"
		| "cancel"
		| "close"
		| "save"
		| "add"
		| "lock"
		| "download"
		| "delete"
	variant?: "text"
		| "outlined"
		| "contained"
	active?: boolean
	icon?: ReactNode
	color?: "inherit"
		| "primary"
		| "secondary"
		| "success"
		| "error"
		| "info"
		| "warning"
	size?: "small"
		| "medium"
		| "large"
	style?: React.CSSProperties
}

class AButton extends React.Component<IAButtonProps> {
	constructor(props: IAButtonProps) {
		super(props)
	}

	render() {
		const {
			label,
			action,
			disabled,
			hidden,
			group,
			type,
			variant,
			active,
			icon,
			color,
			size,
			style,
		} = this.props

		if (hidden) {
			return
		}

		let finalVariant = variant ?? "contained"
		let finalColor = color ?? "primary"

		switch (type) {
			case "save":
				finalColor = "warning"
				finalVariant = "text"
				break
			case "lock":
				finalColor = "warning"
				finalVariant = "text"
				break
			case "delete":
				finalColor = "error"
				finalVariant = "text"
				// finalVariant = "outlined"
				break
			case "add":
				finalColor = "success"
				break
			case "download":
				finalColor = "secondary"
				finalVariant = "text"
				break
			
			case "default":
			case "submit":
			case "confirm":
				// finalColor = "info"
				break
			case "close":
				// finalColor = "info"
				finalVariant = "text"
				break
			case "cancel":
				// finalColor = "warning"
				finalVariant = "text"
				break
		}

		if (disabled || active) {
			finalVariant = "text"
		}

		// if (button.variant) {
		// 	variant = button.variant
		// }

		// if (button.color) {
		// 	color = button.color
		// }

		return <Button
			disabled={active || disabled}
			type={type == "submit" ? "submit" : "button"}
			variant={finalVariant}
			size={size}
			color={finalColor}
			startIcon={icon}
			onClick={e => {
				action && action(e)
			}}
			style={{...style}}
		>
			{label}
		</Button>
	}
}

export default AButton
