import React, { useState, useEffect } from "react"
import {
	useNavigate,
} from "react-router-dom"
import Wrapper from "../Wrapper"
import IReceiverType from "../../api/bo/models/receivertype/IReceiverType"
import ReceiverTypeApi from "../../api/bo/ReceiverTypeApi"
import ReceiverTypeService from "../../services/ReceiverTypeService"
import ReceiverTypeList from "./components/ReceiverTypeList"
import CreateReceiverType from "./components/CreateReceiverType"
import { IAButtonProps } from "../../components/AButton"
import AToolbar from "../../components/AToolbar"

const SettingsScreen = (props: { section?: number }) => {
	const navigate = useNavigate()

	const [receiverTypes, setReceiverTypes] = useState<Array<IReceiverType>>([])
	const [activeReceiverTypeIndex, setActiveReceiverTypeIndex] = useState<number>()

	const [activeSection, setActiveSection] = useState<number>(1)

	const [updateTimer, setUpdateTimer] = useState<ReturnType<typeof setTimeout>>()
	
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [isSaving, setIsSaving] = useState<boolean>(false)

	const [isAddReceiverTypeOpen, setIsAddReceiverTypeOpen] = useState<boolean>(false)

	useEffect(() => {
		refresh()
	}, [])

	const refresh = () => {
		setIsLoading(true)

		Promise.all([
			ReceiverTypeApi.getAll(),
		]).then(values => {
			const receiverTypesResponse = values[0]

			if (!receiverTypesResponse) {
				return
			}

			setReceiverTypes(receiverTypesResponse)
			setIsLoading(false)
			setIsSaving(false) // ugly impl, but should work well enough
		})
	}

	const updateReceiverType = (model: IReceiverType) => {
		setIsLoading(true)

		if (model.receiverTypeId == undefined) {
			save(() => {
				ReceiverTypeService
					.create(model.identifier)
					.then(refresh)
			})
		}
		else {
			save(() => {
				ReceiverTypeService
					.update(model.receiverTypeId, model.identifier)
					.then(refresh)
			})
		}
	}

	const deleteReceiverType = (id: string) => {
		setIsLoading(true)
		
		save(() => {
			ReceiverTypeService.delete(id).then(refresh)
		})
	}

	const save = (callback: () => void) => {
		setIsSaving(true)

		callback()
	}

	const renderSection = () => {
		switch (activeSection) {
			case 2:
				return <ReceiverTypeList
					items={receiverTypes}
					activeIndex={activeReceiverTypeIndex}
					setActiveIndex={setActiveReceiverTypeIndex}
					onUpdate={updateReceiverType}
					onDelete={deleteReceiverType}
				/>
		}

		return
	}

	const toolbarButtons: IAButtonProps[] = [
		{
			label: "inställningar",
			action: () => {
				// navigate("/settings")
				setActiveSection(1)
			},
			active: activeSection == 1,
			type: "default",
		},
		{
			label: "mottagartyper",
			action: () => {
				// navigate("/settings/receivertypes")
				setActiveSection(2)
			},
			active: activeSection == 2,
			type: "default",
		},
		{
			label: "ny mottagartyp",
			action: () => {
				setIsAddReceiverTypeOpen(true)
			},
			hidden: activeSection != 2,
			type: "add",
		},
		{
			label: "radera mottagartyp",
			action: () => {
				if (activeReceiverTypeIndex == undefined) {
					return
				}

				deleteReceiverType(receiverTypes[activeReceiverTypeIndex].receiverTypeId)
			},
			hidden: activeSection != 2,
			disabled: activeReceiverTypeIndex == undefined,
			type: "delete",
		},
		{
			label: isSaving ? "sparar ..." : "allt är sparat",
			action: () => {},
			disabled: true,
			type: "save",
		},
	]

	const breadcrumbs: { label: string, path: string | undefined }[] = [
		{
			label: "start",
			path: "/",
		},
	]

	switch (activeSection) {
		case 1:
			breadcrumbs.push({
				label: "inställningar",
				path: undefined,
			})
			break
		case 2:
			breadcrumbs.push({
				label: "inställningar",
				path: "/settings",
			})
			breadcrumbs.push({
				label: "mottagartyper",
				path: undefined,
			})
			break
	}

	return (
		<Wrapper
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			breadcrumbs={breadcrumbs}
		>
			<AToolbar
				buttons={toolbarButtons}
			/>
			{
				renderSection()
			}
			<CreateReceiverType
				onCreated={(id: string) => {
					setIsAddReceiverTypeOpen(false)
					refresh()
				}}
				showDialog={isAddReceiverTypeOpen}
				onCancel={() => {
					setIsAddReceiverTypeOpen(false)
				}}
			/>
		</Wrapper>
	)
}

export default SettingsScreen
