import React, { useState, useEffect } from "react"
import {
	useLocation,
	Location,
} from "react-router-dom"
import AuthService from "../../services/AuthService"
import Wrapper from "../Wrapper"
import AForm from "../../components/AForm"
import Typer from "../../components/form/Typer"
import AButton from "../../components/AButton"
import AuthForm from "./components/AuthForm"

const Iframe = require("react-iframe").default

const AuthenticateScreen = (props: { returnUrl?: string }) => {
	const locationState = useLocation().state as { from: Location }

	let from: string = "/"

	if (props.returnUrl) {
		from = props.returnUrl
	}
	else if (locationState && locationState.from) {
		from = locationState.from.pathname
	}

	const [username, setUsername] = useState<string>("")
	const [password, setPassword] = useState<string>("")

	const [isLoading, setIsLoading] = useState<boolean>(true)

	useEffect(() => {
		checkIfSignedIn()
	}, [])

	const auth = () => {
		setIsLoading(true)

		AuthService.authenticate(username, password).then(res => {
			if (res) {
				checkIfSignedIn()
			}
			else {
				setIsLoading(false)
			}
		}).catch(reason => {
			setIsLoading(false)
		})
	}

	const checkIfSignedIn = () => {
		setIsLoading(true)

		AuthService.checkIfAuthenticated().then(isAuthenticated => {
			if (isAuthenticated) {
				window.location.href = window.location.href //from || "/"
			}
			else {
				setIsLoading(false)
			}
		}).catch(reason => {
			setIsLoading(false)
		})
	}

	return (
		<Wrapper
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			title="Logga in"
			hideMenu
		>
			<div
				style={{
					maxWidth: 500,
					minWidth: 300,
					position: "absolute",
					left: "50%",
					top: "50%",
					transform: "translate(-50%, -50%)",
				}}
			>
				<AForm
					onSubmit={auth}
				>
					<AuthForm
						username={username}
						password={password}
						setUsername={setUsername}
						setPassword={setPassword}
					/>
					<AButton
						label="Logga in"
						type="submit"
						disabled={!username.length || !password.length}
						color="primary"
						action={auth}
					/>
				</AForm>
			</div>
		</Wrapper>
	)
}

export default AuthenticateScreen
