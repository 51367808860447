import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import React, { ChangeEvent, ReactNode } from "react"
import InputWrapper from "./InputWrapper"
import { TextField } from "@mui/material"

interface SelecterProps {
	label: string
	value: string | string[]
	onSelect: (value: string | string[]) => void
	options: ISelecterOption[]
	description?: string
	placeholder?: string
	displayEmpty?: boolean
	variant?: "outlined" | "filled" | "standard"
	multiple?: boolean
	required?: boolean
	style?: object
}

interface SelecterState {
}

export interface ISelecterOption {
	value: string
	label: string
	key?: string
	disabled?: boolean
}

class Selecter extends React.Component<SelecterProps, SelecterState> {
	constructor(props: SelecterProps) {
		super(props)
	}

	render() {
		const {
			label,
			value,
			onSelect,
			options,
			description,
			placeholder,
			displayEmpty,
			variant,
			multiple,
			required,
			style,
		} = this.props

		return (
			<InputWrapper
				description={description}
				style={{
					margin: "6px 0",
					...style,
				}}
			>
				<TextField
					fullWidth
					required={required}
					value={value}
					label={label}
					placeholder={placeholder}
					variant={variant || "standard"}
					InputLabelProps={{
						shrink: true,
					}}
					select
					SelectProps={{
						multiple,
						value: value,
						onChange: (event: SelectChangeEvent<unknown>, child: ReactNode) => {
							onSelect(event.target.value as typeof value)
						},
						displayEmpty,
					}}
				>
				{
					!options.length
						? <MenuItem value="" disabled>-</MenuItem>
						: options.map(o => {
							return <MenuItem
								value={o.value}
								key={o.key || o.value}
								disabled={o.disabled}
							>
								{o.label}
							</MenuItem>
						})
				}
				</TextField>
				{/* <Select
					value={value}
					onChange={(event: SelectChangeEvent<string>) => {
						onSelect(event.target.value as string)
					}}
					variant={variant || "standard"}
				>
				{
					options.map(o => {
						return <MenuItem value={o.value}>{o.label}</MenuItem>
					})
				}
				</Select> */}
			</InputWrapper>
		)
	}
}

export default Selecter
