import React, { useState, useEffect } from "react"
import {
	useNavigate,
} from "react-router-dom"
import {
	EditRounded,
	AddRounded,
	DeleteRounded,
} from "@mui/icons-material"
import AToolbar from "../../components/AToolbar"

import CaseService from "../../services/CaseService"
import ICase from "../../api/bo/models/case/ICase"
import Wrapper from "../Wrapper"
import CaseList from "./components/CaseList"
import CreateCase from "./components/CreateCase"
import { IAButtonProps } from "../../components/AButton"
import APopConfirm from "../../components/APopConfirm"

const CaseOverviewScreen = () => {
	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState<boolean>(true)

	const [cases, setCases] = useState<Array<ICase>>([])
	const [selectedCases, setSelectedCases] = useState<Array<ICase>>([])
	const [isCreateCaseDialogOpen, setIsCreateCaseDialogOpen] = useState<boolean>(false)
	const [isDeleteCaseDialogOpen, setIsDeleteCaseDialogOpen] = useState<boolean>(false)

	useEffect(() => {
		refresh()
	}, [])

	function refresh() {
		setIsLoading(true)

		CaseService.getAll().then(cases => {
			if (Array.isArray(cases)) {
				setCases(cases)
			}

			setIsLoading(false)
		})
	}

	function goToSelectedCase() {
		if (selectedCases.length !== 1) {
			return
		}

		navigate("/case/" + selectedCases[0].caseId, {
			replace: false,
		})
	}

	function deleteSelected() {
		if (selectedCases.length !== 1) {
			return
		}

		setIsLoading(true)

		CaseService.delete(selectedCases[0].caseId).then((e) => {
			refresh()
		})
		.finally(() => {
			setIsLoading(false)
			setIsDeleteCaseDialogOpen(false)
		})
	}

	// function closeCase() {
	// 	if (selectedCases.length === 0) {
	// 		return
	// 	}

	// 	CaseService.close(selectedCases[0].caseId).then(() => {
	// 		refresh()
	// 	})
	// }

	const oneSelectedCase = selectedCases.length === 1
	// const selectedCaseIsClosed = oneSelectedCase && selectedCases[0].status === CaseStatus.CLOSED

	const toolbarButtons: Array<IAButtonProps> = [
		{
			label: "skapa",
			action: () => {
				setIsCreateCaseDialogOpen(true)
			},
			icon: <AddRounded />,
			type: "add",
		},
		{
			label: "hantera",
			action: goToSelectedCase,
			icon: <EditRounded />,
			disabled: !oneSelectedCase,
			type: "default",
		},
		{
			label: "radera",
			action: () => {
				setIsDeleteCaseDialogOpen(true)
			},
			icon: <DeleteRounded />,
			disabled: !oneSelectedCase,
			type: "delete",
		},
		// {
		// 	label: "stäng ärende",
		// 	action: closeCase,
		// 	icon: oneSelectedCase && !selectedCaseIsClosed
		// 		? <LockOpenRounded />
		// 		: <LockRounded />,
		// 	disabled: !oneSelectedCase || selectedCaseIsClosed,
		// },
	]

	return (
		<Wrapper
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			breadcrumbs={[
				{
					label: "start",
					path: "/"
				},
				{
					label: "ärenden",
				}
			]}
		>
			<AToolbar
				buttons={toolbarButtons}
			/>
			<CaseList
				cases={cases}
				onSelectionChange={(selectedCases: ICase[], selectedCase?: ICase) => {
					setSelectedCases(selectedCases)
				}}
				selectedIds={selectedCases.map(c => { return c.caseId })}
			/>
			<CreateCase
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				showDialog={isCreateCaseDialogOpen}
				onCancel={() => {
					setIsCreateCaseDialogOpen(false)
				}}
				onCreated={(id: string) => {
					refresh()
					setIsCreateCaseDialogOpen(false)
				}}
			/>
			<APopConfirm
				title="Radera ärende"
				message="Är du säker på att du vill radera detta ärende?"
				onCancel={() => {
					setIsDeleteCaseDialogOpen(false)
				}}
				onConfirm={deleteSelected}
				show={isDeleteCaseDialogOpen}
			/>
		</Wrapper>
	)
}

export default CaseOverviewScreen
