import React, { forwardRef, ReactElement } from "react";
import {
	ControlCameraRounded,
	DeleteRounded,
	CheckBoxOutlineBlankRounded,
	CheckBoxRounded,
} from "@mui/icons-material";
import { Typography } from "@mui/material";

interface ListeringProps<T> {
	item: T;
	id: string;
	label: string;
	onClick?: () => void;
	onSelect?: () => void;
	onDelete?: () => void;
	activeContentRenderer?: () => ReactElement;
	active?: boolean;
	selected?: boolean;
	disabled?: boolean;
	sortable?: boolean;
	hideCheckbox?: boolean;
	style?: any;
};

interface ListeringState {
};

class Listering<T> extends React.Component<ListeringProps<T>, ListeringState> {
	constructor(props: ListeringProps<T>) {
		super(props);

		this.state = {
		};
	}

	render() {
		const {
			id,
			label,
			selected,
			onClick,
			onDelete,
			onSelect,
			activeContentRenderer,
			active,
			disabled,
			sortable,
			hideCheckbox,
			style,
		} = this.props;

		return (
			<div
				key={id}
				style={{
					borderRadius: "2px",
					boxShadow: selected
						? "1px 1px 3px rgba(0, 0, 0, 0.7)"
						: "1px 1px 2px rgba(0, 0, 0, 0.5)",
					margin: "6px 0",
					display: "flex",
					flexDirection: "column",
					opacity: disabled
						? "0.49"
						: "1",
					...style,
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "stretch",
						minHeight: "34px",
					}}
				>
				{
					onSelect !== undefined
						&& !hideCheckbox
					? <div
						className="selecter"
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							width: "34px",
							cursor: !disabled
								? "pointer"
								: undefined,
						}}
						onClick={onSelect}
					>
					{
						selected
							? <CheckBoxRounded />
							: <CheckBoxOutlineBlankRounded />
					}
					</div>
					: null
				}
				{
					sortable
					? <div
						className="dragger"
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							width: "34px",
							cursor: "move",
						}}
					>
						<ControlCameraRounded />
					</div>
					: null
				}
					<div
						style={{
							display: "flex",
							alignItems: "center",
							flex: 1,
							paddingLeft: (sortable || (onSelect !== undefined && !hideCheckbox))
								? "0"
								: "8.5px",
							cursor: !disabled && (onClick !== undefined || onSelect !== undefined)
								? "pointer"
								: undefined,
						}}
						onClick={() => {
							if (onClick !== undefined) {
								onClick();
							}
							else if (onSelect !== undefined) {
								onSelect();
							}
						}}
					>
						<Typography
							variant="body1"
							component="span"
							style={{
								margin: "7px 0 5px",
								fontSize: "large",
								lineHeight: 1.2,
							}}
						>
							{label}
						</Typography>
					</div>
					{
						onDelete !== undefined
						? <div
							className="deleter"
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "34px",
							}}
							onClick={() => {
								onDelete !== undefined && onDelete();
							}}
						>
							<DeleteRounded />
						</div>
						: null
					}
				</div>
			{
				active
				&& activeContentRenderer !== undefined
				&& <div
					style={{
						borderTop: "solid 1px #aaaaaa",
						padding: "8.5px",
					}}
				>
				{
					activeContentRenderer !== undefined
						? activeContentRenderer()
						: undefined
				}
				</div>
			}
			</div>
		);
	}
}

export default Listering;