import React, { forwardRef, ReactElement } from "react";
import { ReactSortable } from "react-sortablejs";
import Listering from "./Listering";

interface ListerProps<T> {
	items: T[];
	onSelect?: (selectedItems: T[], selectedItem?: T, index?: number) => void;
	onStart?: () => void;
	onSort?: (items: T[]) => void;
	onClick?: (item: T, index: number) => void;
	onDelete?: (item: T, index: number) => void;
	keyExtractor: (item: T, index: number) => string;
	labelExtractor: (item: T, index: number) => string;
	activeContentRenderer?: (item: T, index: number) => ReactElement;
	selectedKeys: string[];
	disabled?: boolean;
	hideCheckbox?: boolean;
	singleSelect?: boolean;
	style?: any;
	itemStyle?: any;
};

interface ListerState {
	// selectedRows: any[];
	initialSortSorted: boolean;
};

class Lister<T> extends React.Component<ListerProps<T>, ListerState> {
	constructor(props: ListerProps<T>) {
		super(props);

		this.state = {
			// selectedRows: [],
			initialSortSorted: false,
		};
	}

	generateSortableList = () => {
		const {
			items,
			keyExtractor,
		} = this.props;

		return items.map((item, index) => {
			return {
				id: keyExtractor(item, index),
				// When true, the item is selected using MultiDrag.
				selected: false,
				// When true, the item is deemed "chosen", which basically just a mousedown event.
				chosen: false,
				// When true, it will not be possible to pick this item up in the list.
				filtered: false,
				item,
			};
		});
	};

	sort = (list: {
		id: string,
		selected: boolean,
		chosen: boolean,
		filtered: boolean,
		item: any,
	}[]) => {
		const {
			onSort,
		} = this.props;
		const {
			initialSortSorted,
		} = this.state;

		// ugly hack to stop initial sort firing to trigger
		if (!initialSortSorted) {
			this.setState({
				initialSortSorted: true,
			});

			return;
		}

		if (onSort === undefined) {
			return;
		}

		onSort(list.map(l => l.item));
	};

	click = (item: T, index: number) => {
		const {
			onClick,
			onSelect,
		} = this.props;

		if (onClick !== undefined) {
			onClick(item, index);
		}
		else if (onSelect != undefined) {
			this.select(item, index);
		}
	};

	select = (item: T, index: number) => {
		const {
			items,
			onSelect,
			selectedKeys,
			keyExtractor,
			singleSelect,
		} = this.props;

		if (onSelect === undefined) {
			return;
		}

		const id = keyExtractor(item, index);

		let newIndex: number | undefined = index;

		let selectedItems = items.filter((i, j) => {
			return selectedKeys.find(s => s === keyExtractor(i, j)) !== undefined;
		});

		const a = items.findIndex((i, j) => {
			return keyExtractor(i, j) === id
				&& selectedKeys.find(k => k === id) !== undefined;
		});

		const isSelected = selectedKeys.find(k => k === id) !== undefined;

		if (isSelected) {
			newIndex = undefined;
			selectedItems = [];

			if (!singleSelect) {
				for (let i = 0; i < items.length; i++) {
					const k = keyExtractor(items[i], i);
	
					if (selectedKeys.find(s => s === k) !== undefined) {
						if (k !== id) {
							selectedItems.push(items[i]);
						}
					}
				}	
			}
		}
		else {
			if (singleSelect) {
				selectedItems = [item];
			}
			else {
				selectedItems.push(item);
			}
		}

		onSelect(selectedItems, item, newIndex);
	};

	renderItem = (item: T, index: number) => {
		const {
			onSort,
			onClick,
			onDelete,
			onSelect,
			keyExtractor,
			labelExtractor,
			activeContentRenderer,
			selectedKeys,
			disabled,
			hideCheckbox,
			itemStyle,
		} = this.props;

		const id = keyExtractor(item, index);

		const active = selectedKeys.length === 1
			&& selectedKeys.find(k => k === id) !== undefined;

		return <Listering<T>
			item={item}
			key={id}
			id={id}
			label={labelExtractor(item, index)}
			onClick={onClick !== undefined || onSelect !== undefined
				? () => { this.click(item, index); }
				: undefined}
			onSelect={onSelect !== undefined
				? () => { this.select(item, index); }
				: undefined}
			onDelete={onDelete !== undefined
				? () => { onDelete(item, index); }
				: undefined}
			selected={selectedKeys.find(k => k === id) !== undefined}
			disabled={disabled}
			sortable={!disabled && onSort !== undefined}
			hideCheckbox={hideCheckbox}
			style={itemStyle}
			active={active}
			activeContentRenderer={active
				&& activeContentRenderer !== undefined
				? () => {
					return activeContentRenderer(item, index);
				}
				: undefined
			}
		/>;
	};

	render() {
		const {
			items,
			onStart,
			onSort,
			disabled,
			style,
		} = this.props;

		if (onSort !== undefined) {
			const sortableList = this.generateSortableList();

			return (
				<ReactSortable
					list={sortableList}
					setList={this.sort}
					handle=".dragger"
					animation={200}
					// delayOnTouchStart={true}
					delay={2}
					disabled={disabled}
					onStart={onStart}
				>
				{
					sortableList.map((sortableItem, index) => {
						return this.renderItem(sortableItem.item, index);
					})
				}
				</ReactSortable>
			);
		}

		return (
			<div
				style={{
					flex: 1,
					display: "flex",
					flexFlow: "column",
					// height: "100%",
					...style,
				}}
			>
			{
				items.map((item, index) => {
					return this.renderItem(item, index);
				})
			}
			</div>
		);
	}
}

export default Lister;