import React from "react"
import ManageQuestion from "./ManageQuestion"
import IAnswer from "../../../api/bo/models/questionnaire/IAnswer"
import IQuestion from "../../../api/bo/models/questionnaire/IQuestion"
import QuestionType from "../../../api/bo/models/questionnaire/QuestionType"
import APopForm from "../../../components/APopForm"

interface AddQuestionProps {
	questions: IQuestion[]
	addQuestion: (question: IQuestion) => void
	isAddQuestionDialogOpen: boolean
	closeAddQuestionDialog: () => void
}

interface AddQuestionState {
	question: IQuestion
}

class AddQuestion extends React.Component<AddQuestionProps, AddQuestionState> {
	constructor(props: AddQuestionProps) {
		super(props)

		const {
			questions,
		} = this.props

		const questionsWithAnswers = questions.filter(q =>
			q.type != QuestionType.FreeText && q.derivedAnswersId == undefined)
		const useDerivedAnswersFromQuestion = questionsWithAnswers[questionsWithAnswers.length - 1]
		const derivedAnswersId = questionsWithAnswers.length > 0
			? useDerivedAnswersFromQuestion.questionId
			: undefined

		let q: IQuestion = {
				questionId: undefined,
				order: questions.length + 1,
				type: derivedAnswersId != undefined
					? QuestionType.SingleChoice
					: QuestionType.FreeText,
				derivedAnswersId: derivedAnswersId,
				text: "",
				answers: [],
			}

		this.state = {
			question: q,
		}
	}

	setQuestionType = (order: number, type: QuestionType) => {
		const {
			question,
		} = this.state

		question.type = type
		question.derivedAnswersId = undefined

		this.setState({
			question,
		})
	}

	setQuestionText = (order: number, text: string) => {
		const {
			question,
		} = this.state

		question.text = text

		this.setState({
			question,
		})
	}

	moveQuestion = (from: number, to: number) => {
		const {
			question,
		} = this.state

		question.order = to

		this.setState({
			question,
		})
	}

	setDerivedAnswersId = (order: number, id?: string) => {
		const {
			question,
		} = this.state

		question.derivedAnswersId = id

		this.setState({
			question,
		})
	}

	setAnswers = (order: number, answers: IAnswer[]) => {
		const {
			question,
		} = this.state

		question.answers = answers

		this.setState({
			question,
		})
	}

	generateAddQuestion = () => {
		const {
			questions,
		} = this.props
		const {
			question,
		} = this.state

		return <ManageQuestion
			isAdding
			question={question}
			questions={questions}
			moveQuestion={this.moveQuestion}
			setQuestionType={this.setQuestionType}
			setQuestionText={this.setQuestionText}
			setUseAnswersFrom={this.setDerivedAnswersId}
			setAnswers={this.setAnswers}
		/>
	}

	addQuestion = () => {
		const {
			addQuestion,
		} = this.props
		const {
			question,
		} = this.state

		addQuestion(question)
	}

	render() {
		const {
			isAddQuestionDialogOpen,
			closeAddQuestionDialog,
		} = this.props

		return (
			<APopForm
				title="Ny fråga"
				show={isAddQuestionDialogOpen}
				onClose={closeAddQuestionDialog}
				onSubmit={this.addQuestion}
			>
			{
				this.generateAddQuestion()
			}
			</APopForm>
		)
	}
}

export default AddQuestion
