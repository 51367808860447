import React from "react"
import IReceiverType from "../../../api/bo/models/receivertype/IReceiverType"
import Typer from "../../../components/form/Typer"

interface ManageReceiverTypeProps {
	item: IReceiverType
	updateReceiverType: (model: IReceiverType) => void
}

interface ManageReceiverTypeState {
	identifier: string
	updateTimer?: ReturnType<typeof setTimeout>
}

class ManageReceiverType extends React.Component<ManageReceiverTypeProps, ManageReceiverTypeState> {
	constructor(props: ManageReceiverTypeProps) {
		super(props)

		const {
			item,
		} = this.props

		this.state = {
			identifier: item.identifier,
			updateTimer: undefined,
		}
	}

	saveReceiverType = () => {
		const {
			identifier,
		} = this.state
		const {
			item,
			updateReceiverType,
		} = this.props

		updateReceiverType({
			...item,
			identifier,
		})
	}

	handleUpdate = () => {
		const {
			updateTimer,
		} = this.state

		if (updateTimer !== undefined) {
			clearTimeout(updateTimer)
		}

		this.setState({
			updateTimer: setTimeout(this.saveReceiverType, 2000)
		})
	}

	setIdentifier = (identifier: string) => {
		this.setState({
			identifier,
		}, this.handleUpdate)
	}

	renderIdentifierInput = () => {
		const {
			identifier,
		} = this.state

		return <Typer
			label="Etikett"
			type="text"
			value={identifier}
			required
			onTyped={(val) => {
				this.setIdentifier(val)
			}}
			description={"Etikett för att hålla reda på."}
		/>
	}

	render() {
		return (
			<>
			{
				this.renderIdentifierInput()
			}
			</>
		)
	}
}

export default ManageReceiverType
