import React from "react"
import {
	Typography,
} from "@mui/material"
import IReceiverType from "../../../api/bo/models/receivertype/IReceiverType"
import Lister from "../../../components/lister/Lister"
import ManageReceiverType from "./ManageReceiverType"

interface ReceiverTypeListProps {
	items: IReceiverType[]
	activeIndex?: number
	setActiveIndex: (index?: number) => void
	onUpdate: (model: IReceiverType) => void
	onDelete: (id: string) => void
}

interface ReceiverTypeListState {
}

class ReceiverTypeList extends React.Component<ReceiverTypeListProps, ReceiverTypeListState> {
	constructor(props: ReceiverTypeListProps) {
		super(props)
	}

	render() {
		const {
			items,
			setActiveIndex,
			activeIndex,
			onUpdate,
			onDelete,
		} = this.props

		return (
			<div
				style={{
					flex: 1,
					display: "flex",
					flexDirection: "row",
					height: "100%",
				}}
			>
				<div
					style={{
						flex: 0.8,
						display: "flex",
						flexFlow: "column",
						alignItems: "stretch",
						// height: "100%",
					}}
				>
					<Typography
						style={{
							margin: "8.5px 0",
							fontSize: "17px",
							lineHeight: 1,
							letterSpacing: 1.5,
							textTransform: "uppercase",
							color: "#111111",
						}}
					>
						mottagartyper
					</Typography>
					<Lister<IReceiverType>
						items={items}
						selectedKeys={
							activeIndex !== undefined
							? [activeIndex.toString()]
							: []
						}
						activeContentRenderer={(item, index) => {
							return <ManageReceiverType
								item={item}
								updateReceiverType={onUpdate}
							/>
						}}
						keyExtractor={(item, index) => index.toString()}
						labelExtractor={(item, index) => item.identifier}
						onSelect={(items, item, index) => {
							setActiveIndex(index)
						}}
						onDelete={(item, index) => {
							onDelete(item.receiverTypeId)
						}}
						hideCheckbox
						singleSelect
						itemStyle={{
							margin: "4.25px 6.5px 4.25px 2px",
						}}
					/>
				</div>
			</div>
		)
	}
}

export default ReceiverTypeList
