import IQuestionnaire from "../api/bo/models/questionnaire/IQuestionnaire"
import QuestionnaireManagementApi from "../api/bo/QuestionnaireManagementApi"

export default class QuestionnaireManagementService {
	public static getAll() {
		return QuestionnaireManagementApi.getAll().then(questionnaires => {
			return questionnaires
		})
	}
	
	public static get(id: string) {
		return QuestionnaireManagementApi.getOne(id).then(questionnaire => {
			return questionnaire
		})
	}
	
	public static create(questionnaire: IQuestionnaire) {
		return QuestionnaireManagementApi.create(questionnaire).then(id => {
			return id
		})
	}
	
	public static update(id: string, questionnaire: IQuestionnaire) {
		return QuestionnaireManagementApi.update(id, questionnaire)
	}
	
	public static lock(id: string) {
		return QuestionnaireManagementApi.lock(id)
	}
	
	public static delete(id: string) {
		return QuestionnaireManagementApi.delete(id)
	}
}
