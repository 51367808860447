import React from "react"
import {
	FormControlLabel,
	RadioGroup,
	Radio,
	Button,
	MobileStepper,
	TextField,
} from "@mui/material"
import {
	ArrowBackIosRounded,
	ArrowForwardIosRounded,
} from "@mui/icons-material"
import IClientQuestion from "../../../api/client/models/IClientQuestion"
import QuestionType from "../../../api/bo/models/questionnaire/QuestionType"
import IClientSubmitAnswer from "../../../api/client/models/IClientSubmitAnswer"

interface SteppingProps {
	questions: IClientQuestion[]
	activeQuestionId?: string
	setActiveQuestionId: (questionId: string) => void
	pickAnswer: (questionId: string, answerId: string) => void
	setFreeTextAnswer: (questionId: string, text: string) => void
	getProvidedAnswer: (questionId: string) => IClientSubmitAnswer | undefined
}

interface SteppingState {
}

class Stepping extends React.Component<SteppingProps, SteppingState> {
	constructor(props: SteppingProps) {
		super(props)

		const {
			questions,
			setActiveQuestionId,
		} = this.props

		if (questions.length) {
			setActiveQuestionId(questions[0].questionId)
		}

		// document.addEventListener("keypress", this.keyPress)
	}

	// find out why this works like shit
	// keyPress = (event: KeyboardEvent) => {
	// 	try {
	// 		let numKey = parseInt(event.key)

	// 		this.pickAnswerByOrder(numKey)
	// 	}
	// 	catch (e) {
	// 		switch (event.key) {
	// 			case "ArrowLeft":
	// 				this.goPreviousQuestion()
	// 				break
	// 			case "ArrowRight":
	// 				this.goNextQuestion()
	// 				break
	// 		}
	// 	}
	// }

	getActiveQuestionIndex = () => {
		const {
			questions,
			activeQuestionId,
		} = this.props

		return questions.findIndex(q => q.questionId === activeQuestionId)
	}

	goPreviousQuestion = () => {
		const {
			questions,
			setActiveQuestionId,
		} = this.props

		const index = this.getActiveQuestionIndex()
		const question = questions[index - 1]

		if (question === undefined) {
			return
		}

		setActiveQuestionId(question.questionId)
	}

	goNextQuestion = () => {
		const {
			questions,
			setActiveQuestionId,
		} = this.props

		const index = this.getActiveQuestionIndex()
		const question = questions[index + 1]

		if (question === undefined) {
			return
		}

		setActiveQuestionId(question.questionId)
	}

	// pickAnswerByOrder = (order: number) => {
	// 	const aq = this.getActiveQuestion()

	// 	if (aq === undefined
	// 		|| aq.answers.length < order) {
	// 		return
	// 	}
		
	// 	this.pickAnswer(aq.answers[order - 1].answerId)
	// }

	pickAnswer = (answerId: string) => {
		const {
			pickAnswer,
			activeQuestionId,
		} = this.props

		if (activeQuestionId == undefined) {
			return
		}

		pickAnswer(activeQuestionId, answerId)
	}

	setFreeTextAnswer = (text: string) => {
		const {
			setFreeTextAnswer,
			activeQuestionId,
		} = this.props

		if (activeQuestionId == undefined) {
			return
		}

		setFreeTextAnswer(activeQuestionId, text)
	}

	render() {
		const {
			questions,
			getProvidedAnswer,
			activeQuestionId,
		} = this.props

		if (activeQuestionId == undefined) {
			return
		}
		
		const activeQuestion = questions.find(q => q.questionId == activeQuestionId)
		const providedAnswer = getProvidedAnswer(activeQuestionId)
		const activeIndex = this.getActiveQuestionIndex()

		if (activeQuestion == undefined) {
			return
		}

		return (
			<div
				style={{
					// display: "flex",
					// flex: "1",
					// flexDirection: "column",
				}}
			>
				{/* <div
					style={{
						// margin: "1vh auto 0",
						textAlign: "center",
					}}
				>
					<Typography
						style={{
							fontSize: "23px",
							letterSpacing: 1.5,
							// textTransform: "uppercase",
							color: "#111111",
						}}
					>
						{activeQuestion.text}
					</Typography>
				</div> */}
				<div
					style={{
						// width: "90%",
						// maxWidth: "500px",
						margin: "17px auto",
						display: "flex",
						justifyContent: "center",
					}}
				>
				{
					activeQuestion.type == QuestionType.SingleChoice
					? <RadioGroup
						name={"answers-" + activeQuestionId}
						// value={providedAnswer?.answerId}
						onChange={(event, value) => this.pickAnswer(value) }
					>
					{
						activeQuestion.answers
							.map((a, i) => {
								return <FormControlLabel
									key={"answer-" + a.answerId}
									value={a.answerId}
									control={<Radio
										color="primary"
										autoFocus={i == 0}
									/>}
									label={a.text}
									checked={a.answerId == providedAnswer?.answerId}
								/>
							})
					}
					</RadioGroup>
					: <TextField
						name={"answers-" + activeQuestionId}
						onChange={(event) => {
							this.setFreeTextAnswer(event.target.value)
						}}
						autoFocus={true}
						multiline={true}
						maxRows={5}
						rows={5}
						style={{
							width: "91%",
							maxWidth: "500px",
						}}
						placeholder="Skriv ditt svar här ..."
						value={providedAnswer !== undefined
							? providedAnswer.text
							: ""}
					/>
				}
				</div>
				<MobileStepper
					variant="text"
					style={{
						padding: 0,
					}}
					steps={questions.length}
					position="static"
					activeStep={activeIndex}
					className="stepper"
					backButton={
						<Button
							size="medium"
							onClick={this.goPreviousQuestion}
							disabled={activeIndex === 0}
							startIcon={<ArrowBackIosRounded />}
						>
							Förra
						</Button>
					}
					nextButton={
						<Button
							size="small"
							onClick={this.goNextQuestion}
							disabled={activeIndex === questions.length - 1
								|| providedAnswer === undefined}
							endIcon={<ArrowForwardIosRounded />}
						>
							Nästa
						</Button>
					}
				/>
			</div>
		)
	}
}

export default Stepping
