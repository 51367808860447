import React from "react"

export interface IAFormProps {
	onSubmit: () => void
	children: React.ReactNode
}

class AForm extends React.Component<IAFormProps> {
	constructor(props: IAFormProps) {
		super(props)
	}

	render() {
		const {
			onSubmit,
			children,
		} = this.props

		return <form
			onSubmit={(e) => {
				onSubmit()
				e.preventDefault()
			}}
		>
			{children}
		</form>
	}
}

export default AForm
