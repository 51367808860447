import React, { ReactNode } from "react"
import { ButtonGroup, Button } from "@mui/material"
import AButton, { IAButtonProps } from "./AButton"

export interface IAToolbarFilter {
	label: string
	action: Function
	disabled?: boolean
	value?: boolean
}

interface IAToolbarProps {
	buttons?: Array<IAButtonProps>
	filters?: Array<IAToolbarFilter>
	size?: "small" | "medium" | "large"
	style?: React.CSSProperties
}

class AToolbar extends React.Component<IAToolbarProps> {
	constructor(props: IAToolbarProps) {
		super(props)
	}

	renderButtons = () => {
		const {
			buttons,
		} = this.props

		if (buttons === undefined || !buttons.length) {
			return (<></>)
		}

		let groupedButtons: { group?: string, buttons: IAButtonProps[] }[] = []
		
		for (let i in buttons) {
			const b = buttons[i]

			if (b.group === undefined) {
				groupedButtons.push({ group: undefined, buttons: [b] })
			}
			else {
				if (!groupedButtons.some((v, i) => {
					return v.group === b.group
				})) {
					groupedButtons.push({ group: b.group, buttons: [] })
				}

				for (let j in groupedButtons) {
					if (groupedButtons[j].group === b.group) {
						groupedButtons[j].buttons.push(b)
					}
				}
			}
		}

		return (
			<div
				style={{
					flex: 1,
					display: "flex",
					flexDirection: "row",
				}}
			>
			{
				groupedButtons.map((b, i) => {
					return this.renderButton(b.buttons, i)
				})
			}
			</div>
		)
	}

	renderButton = (buttons: IAButtonProps[], index: number) => {
		const {
			size,
		} = this.props

		let hide = true

		for (let i in buttons) {
			if (!buttons[i].hidden) {
				hide = false
			}
		}

		if (hide) {
			return
		}

		return (<div
			style={{
				marginRight: 7,
				marginBottom: 6,
				marginTop: 6,
			}}
			key={"toolbar-button-" + index}
		>
			{
				buttons.length > 1
				? <ButtonGroup
					variant={buttons[0].variant}
				>
				{
					buttons.map((b, i) => {
						return <AButton
							{...b}
							key={"toolbar-button-" + index + "" + i}
							size={size ?? "small"}
						/>
					})
				}
				</ButtonGroup>
				: <AButton
					{...buttons[0]}
					size={size ?? "small"}
				/>
			}
		</div>)
	}

	render() {
		const {
			style,
		} = this.props

		return (
			<div
				style={{
					flex: "none",
					...style,
				}}
			>
				{this.renderButtons()}
				{/* <div
					className="filters"
				>
					<div
						className="control"
					>
						<FormControlLabel
							label="visa dolda ärenden"
							control={
								<Switch
									size="normal"
									color="primary"
									checked={showClosed}
									onChange={this.toggleClosed} />
							}
						/>
					</div>
				</div> */}
			</div>
		)
	}
}

export default AToolbar
