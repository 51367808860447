import { useState, useEffect } from "react"
import {
	useParams,
} from "react-router-dom"
import SendOutService from "../../services/SendOutService"
import QuestionnaireManagementService from "../../services/QuestionnaireManagementService"
import AnswersList from "./components/AnswersList"

import CaseService from "../../services/CaseService"
import CalculationService from "../../services/CalculationService"
import Calculation from "./components/Calculation"
import ICase from "../../api/bo/models/case/ICase"
import IQuestionnaire from "../../api/bo/models/questionnaire/IQuestionnaire"
import ICalculation from "../../api/bo/models/questionnaire/ICalculation"
import ISendOut from "../../api/bo/models/sendout/ISendOut"
import Wrapper from "../Wrapper"
import IQuestion from "../../api/bo/models/questionnaire/IQuestion"
import IAnswer from "../../api/bo/models/questionnaire/IAnswer"
import AToolbar from "../../components/AToolbar"
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef"
import CalculationMethod from "../../api/bo/models/questionnaire/CalculationMethod"
import SignatureType from "../../api/client/models/SignatureType"
import moment from "moment"
import QuestionType from "../../api/bo/models/questionnaire/QuestionType"
import { IAButtonProps } from "../../components/AButton"
import ISendOutAnswer from "../../api/bo/models/sendout/ISendOutAnswer"

const AnswersScreen = () => {
	const { caseId } = useParams<"caseId">()
	const { sendOutId } = useParams<"sendOutId">()

	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [initialized, setInitialized] = useState<boolean>(false)
	const [activeTab, setActiveTab] = useState<number>(1)

	const [theCase, setTheCase] = useState<ICase | undefined>(undefined)
	const [sendOut, setSendOut] = useState<ISendOut | undefined>(undefined)
	const [questionnaire, setQuestionnaire] = useState<IQuestionnaire | undefined>(undefined)
	const [calculations, setCalculations] = useState<ICalculation[]>([])
	const [calculation, setCalculation] = useState<ICalculation | undefined>(undefined)
	const [fullQuestionAnswerData, setFullQuestionAnswerData] = useState<{
		questionId: string
		questionOrder: number
		questionText: string
		answerValue?: number
		answerText: string
	}[]>([])

	useEffect(() => {
		if (caseId == undefined || sendOutId == undefined) {
			return
		}

		setIsLoading(true)

		Promise.all([
			CaseService.get(caseId),
			SendOutService.getByKey(sendOutId),
		]).then(x => {
			const caseResponse = x[0] as ICase
			const sendoutResponse = x[1] as ISendOut

			setTheCase(caseResponse)
			setSendOut(sendoutResponse)

			Promise.all([
				QuestionnaireManagementService.get(sendoutResponse.questionnaireId),
				CalculationService.getAllForQuestionnaire(sendoutResponse.questionnaireId),
			]).then(y => {
				const questionnaireResponse = y[0] as IQuestionnaire
				const calculationsResponse = y[1] as ICalculation[]

				setQuestionnaire(questionnaireResponse)
				setCalculations(calculationsResponse)

				setInitialized(true)
				setIsLoading(false)
			})
		})
	}, [])

	useEffect(() => {
		const fullQuestionAnswerData = getFullQuestionAnswerData()

		setFullQuestionAnswerData(fullQuestionAnswerData)
	}, [initialized])

	const breadcrumbs: { label: string, path?: string }[] = [
		{
			label: "start",
			path: "/"
		},
		{
			label: "ärenden",
			path: "/cases",
		},
		{
			label: theCase !== undefined ?
				theCase.identifier
					+ (theCase?.dateOfBirth !== undefined
						? ""
						: " " + CaseService.getFormattedDob(theCase.dateOfBirth))
				: "...",
			path: "/case/" + caseId,
		},
		{
			label: sendOut?.description || "...",
		}
	]

	const toolbarButtons: Array<IAButtonProps> = [
		{
			label: "Översikt",
			action: () => {
				setActiveTab(1)
			},
			type: "default",
			active: activeTab == 1,
		},
		{
			label: "Inskickade svar",
			action: () => {
				setActiveTab(2)
			},
			type: "default",
			active: activeTab == 2,
		},
		{
			label: "Vikta",
			action: () => {
				setActiveTab(3)
			},
			type: "default",
			active: activeTab == 3,
		},
		{
			label: "Exportera som PDF",
			action: () => {
				SendOutService.downloadPdf(
					generateCalculationTable(),
					getBaseInfoRows(),
					getFullQuestionAnswerData()
				)
			},
			type: "download",
		},
		// {
		// 	label: "spara",
		// 	action: () => {
		// 		// handle
		// 	},
		// },
	]

	const getBaseInfoRows = () => {
		let signature = ""
		let receiverTypeIdentifier = ""
		// let signature: string[] = []

		if (sendOut) {
			receiverTypeIdentifier = "ÖVRIG"

			if (sendOut.receiverTypeIdentifier) {
				receiverTypeIdentifier = sendOut.receiverTypeIdentifier.toUpperCase()
			}

			if (!sendOut.clientDetails) {
				signature = "[ANONYMISERAD]"
			}
			else {
				signature = sendOut.clientDetails.firstName + " "
					+ sendOut.clientDetails.lastName + " "
					+ "(" + sendOut.clientDetails.personId + ") "
	
				switch (sendOut.signatureType) {
					case SignatureType.BankId:
						signature += "BANKID"
						break
					default:
						signature += "MANUELL"
						break
				}
			}
		}

		return [
			{
				label: "Ärende",
				value: theCase !== undefined
					? theCase.identifier
						+ (
							!theCase.dateOfBirth
								? ""
								: " (" + CaseService.getFormattedDob(theCase.dateOfBirth) + ")"
						)
					: "",
			},
			{
				label: "Formulär",
				value: questionnaire?.identifier ?? "",
			},
			{
				label: "Mottagartyp",
				value: receiverTypeIdentifier,
			},
			{
				label: "Skickat till",
				value: sendOut?.description ?? "",
			},
			{
				label: "Signerat av",
				value: signature,
			}
		]
	}

	const getFullQuestionAnswerData = () => {
		if (sendOut === undefined
			|| sendOut.answers === undefined
			|| questionnaire === undefined
			|| questionnaire.questions === undefined
		) {
			return []
		}

		let questionAnswers: {
			questionId: string,
			questionOrder: number,
			questionText: string,
			answerValue?: number,
			answerText: string,
		}[] = []

		sendOut.answers.forEach((sa: ISendOutAnswer) => {
			const question: IQuestion | undefined = questionnaire.questions.find((q: IQuestion) => q.questionId === sa.questionId)

			if (question === undefined) {
				return
			}

			let answerValue = undefined
			let answerText = "Error"

			if (question.type === QuestionType.FreeText) {
				if (sa.text === undefined) {
					return
				}

				answerText = sa.text
			}
			else if (sa.answerId !== undefined) {
				let answer: IAnswer | undefined = question.answers.find((a: IAnswer) => a.answerId === sa.answerId)
	
				let derivedFromQuestion: IQuestion | undefined = undefined
	
				if (question.derivedAnswersId) {
					derivedFromQuestion = questionnaire.questions.find((q: IQuestion) => q.questionId === question.derivedAnswersId)
					answer = derivedFromQuestion!.answers.find((a: IAnswer) => a.answerId == sa.answerId)
				}
	
				if (answer == undefined) {
					return
				}

				answerValue = answer.value
				answerText = answer.text
			}
			
			questionAnswers.push({
				questionId: question.questionId!,
				questionOrder: question.order,
				questionText: question.text,
				answerValue: answerValue,
				answerText: answerText,
			})
		})

		return questionAnswers.sort((a, b) => {
			return a.questionOrder - b.questionOrder
		})
	}

	const generateCalculationTable = () => {
		const columns: GridColDef<any>[] = []
		const rows: {[key: string]: string}[] = []

		if (calculation != undefined
			&& sendOut != undefined) {
			columns.push({
				field: "part",
				headerName: "Del",
				flex: 4,
			})
			columns.push({
				field: "result",
				headerName: "Resultat",
				type: "numeric",
				flex: 1,
			})

			if (calculation.parts.some(x => x.cutOffs.some(y => y.receiverTypeId == sendOut.receiverTypeId))) {
				columns.push({
					field: "cutOff",
					headerName: "Gränsvärde",
					type: "numeric",
					flex: 1,
				})
			}

			for (let i in calculation.parts) {
				const part = calculation.parts[i]
				const row: {[key: string]: string} = {}

				row["part"] = part.identifier
				row["partId"] = part.partId || ""
				
				const partAnswers = fullQuestionAnswerData
					.filter(a => part.questionIds.some(q => q == a.questionId))
				const partAnswersValues = partAnswers
					.filter(a => a.answerValue != undefined)
					.map(a => a.answerValue!)
					.sort((a, b) => a - b)

				let sum: number = partAnswersValues.reduce((a, b) => a + b, 0)

				switch (part.method) {
					case CalculationMethod.Average:
						sum = sum / partAnswersValues.length
						break
					case CalculationMethod.Median:
						const mid = Math.floor(partAnswersValues.length / 2)
						
						sum = partAnswersValues.length % 2 !== 0
							? partAnswersValues[mid]
							: (partAnswersValues[mid - 1] + partAnswersValues[mid]) / 2
						break
					case CalculationMethod.Sum:
						break
				}

				row["result"] = sum.toFixed(3)

				const cutOff = part.cutOffs.find(x => x.receiverTypeId == sendOut.receiverTypeId)

				if (cutOff != undefined) {
					row["cutOff"] = cutOff.amount.toFixed(3)
				}

				rows.push(row)
			}
		}

		return { columns, rows }
	}

	const renderActiveSection = () => {
		switch (activeTab) {
			case 2:
				return <AnswersList
					rows={fullQuestionAnswerData}
				/>
			case 3:
				return <Calculation
					calculations={calculations}
					selectedCalculationId={calculation?.calculationId || ""}
					setCalculation={setCalculation}
					tableData={generateCalculationTable()}
				/>
			default:
				return <table
					style={{
						width: "100%",
					}}
				>
					<tbody>
					{
						getBaseInfoRows().map(row => {
							return <tr
								key={row.label}
							>
								<th
									style={{
										width: "20%",
										textAlign: "left",
									}}
								>{row.label}</th>
								<td>{row.value}</td>
							</tr>
						})
					}
					</tbody>
				</table>
		}
	}

	return (
		<Wrapper
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			breadcrumbs={breadcrumbs}
			contentType="data"
		>
			<AToolbar
				buttons={toolbarButtons}
			/>
			{
				renderActiveSection()
			}
		</Wrapper>
	)
}

export default AnswersScreen
