import React from "react"
import CaseService from "../../../services/CaseService"
import APopForm from "../../../components/APopForm"
import ManageCase from "../../case/components/ManageCase"

interface CreateCaseProps {
	onCreated: (id: string) => void
	onCancel: () => void
	isLoading: boolean
	setIsLoading: (isLoading: boolean) => void
	showDialog: boolean
}
interface CreateCaseState {
	identifier: string
	dateOfBirth?: Date
	id?: string
	error: string
}

class CreateCase extends React.Component<CreateCaseProps, CreateCaseState> {
	constructor(props: CreateCaseProps) {
		super(props)

		this.state = {
			identifier: "",
			error: "",
		}
	}

	createCase = () => {
		const {
			onCreated,
			setIsLoading,
		} = this.props
		const {
			identifier,
			dateOfBirth,
		} = this.state

		if (!identifier.length) {
			this.setState({
				error: "Vänligen, ange etikett för att kunna skapa ärende",
			})

			return
		}

		setIsLoading(true)

		CaseService.create(identifier, dateOfBirth).then(id => {
			if (id !== undefined) {
				this.setState({
					identifier: "",
					dateOfBirth: undefined,
					error: "",
				}, () => {
					setIsLoading(false)
					onCreated(id)
				})
			}
		})
	}

	cancel = () => {
		const {
			onCancel,
		} = this.props

		this.setState({
			identifier: "",
			error: "",
		}, onCancel)
	}

	render() {
		const {
			identifier,
			dateOfBirth,
			error,
		} = this.state
		const {
			showDialog,
		} = this.props

		return <APopForm
			title="Skapa ärende"
			show={showDialog}
			onSubmit={this.createCase}
			onClose={this.cancel}
		>
			<ManageCase
				identifier={identifier}
				dateOfBirth={dateOfBirth}
				setIdentifier={(value: string) => {
					this.setState({
						identifier: value,
					})
				}}
				setDateOfBirth={(dateOfBirth?: Date) => {
					this.setState({
						dateOfBirth,
					})
				}}
			/>
		</APopForm>
	}
}

export default CreateCase
