import ClientQuestionnaireApi from "../api/client/ClientQuestionnaireApi"
import IClientSubmitAnswer from "../api/client/models/IClientSubmitAnswer"
import SignatureType from "../api/client/models/SignatureType"
import IClientDetails from "../api/models/IClientDetails"

export default class ClientQuestionnaireService {
	public static get(key: string) {
		return ClientQuestionnaireApi.getOne(key).then(questionnaire => {
			return questionnaire
		})
	}

	public static submit(
		key: string,
		signatureType: SignatureType,
		answers: IClientSubmitAnswer[],
		clientDetails?: IClientDetails,
	) {
		return ClientQuestionnaireApi.submit(
			key,
			signatureType,
			answers,
			clientDetails,
			// clientPercievedTargetCase
		).then(x => {
			return x
		})
	}

	public static cancelSign(orderRef: string) {
		return ClientQuestionnaireApi.cancelSign(orderRef).then(x => {
			return x
		})
	}

	public static getQrCode(orderRef: string) {
		return ClientQuestionnaireApi.getQrCode(orderRef).then(x => {
			return x
		})
	}

	public static collectBankIdSignature(orderRef: string) {
		return ClientQuestionnaireApi.collectBankIdSignature(orderRef).then(x => {
			return x
		})
	}
}
