import React from "react"
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef"
import ICalculation from "../../../../api/bo/models/questionnaire/ICalculation"
import ATable from "../../../../components/ATable"

const columns = [
	{
		field: "identifier",
		headerName: "Etikett",
		flex: 1,
	},
] as GridColDef<ICalculation>[]

interface CalculationListProps {
	calculations: ICalculation[]
	onSelectionChange: (selectedCalcs: ICalculation[], selectedCalc?: ICalculation) => void
	selectedIds: string[]
}

interface CalculationListState {
}

class CalculationList extends React.Component<CalculationListProps, CalculationListState> {
	constructor(props: CalculationListProps) {
		super(props)

		this.state = {
		}
	}

	render() {
		const {
			calculations,
			onSelectionChange,
			selectedIds,
		} = this.props
		// const rows = cases
		// 	// .filter(f => { if (showClosed || f.status !== CASE_STATUS.CLOSED) { return f } })
		// 	.map(f => {
		// 		return f
		// 	})

		return (
			<ATable
				columns={columns}
				rows={calculations}
				onSelectionChange={onSelectionChange}
				uniqueKey="calculationId"
				selectedKeys={selectedIds}
			/>
		)
	}
}

export default CalculationList