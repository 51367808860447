import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef"
import React from "react"
import ATable from "../../../components/ATable"

const columns: GridColDef[] = [
	{
		field: "questionOrder",
		headerName: "",
		type: "number",
		flex: 0.5,
		disableColumnMenu: true,
	},
	{
		field: "questionText",
		headerName: "Fråga",
		flex: 4,
	},
	{
		field: "answerText",
		headerName: "Svar",
		flex: 4,
	},
	{
		field: "answerValue",
		headerName: "",
		type: "number",
		flex: 0.5,
		disableColumnMenu: true,
	},
] as GridColDef<{
	questionId: string,
	questionOrder: number,
	questionText: string,
	answerValue?: number,
	answerText: string,
}>[]

interface AnswersListProps {
	rows: {
		questionId: string,
		questionOrder: number,
		questionText: string,
		answerValue?: number,
		answerText: string,
	}[]
}

interface AnswersListState {
}

class AnswersList extends React.Component<AnswersListProps, AnswersListState> {
	constructor(props: AnswersListProps) {
		super(props)

		this.state = {
		}
	}

	render() {
		const {
			rows,
		} = this.props

		return (
			<div
				style={{
					flex: 1,
					height: "100%",
					marginRight: "6.5px",
				}}
			>
				{/* <Typography
					style={{
						margin: "0",
						fontSize: "15px",
						lineHeight: "27px",
						color: "#111111",
						fontWeight: "bold",
						textTransform: "lowercase",
					}}
				>
					Mottagna svar
				</Typography> */}
				<ATable
					columns={columns}
					rows={rows}
					uniqueKey="questionId"
					disableSelection={true}
					sortBy={[{ field: "questionOrder", sort: "asc" }]}
				/>
			</div>
		)
	}
}

export default AnswersList
