import React, { ChangeEvent } from "react"
import {
	Button,
} from "@mui/material"
import DeviceHelper from "../../../helpers/DeviceHelper"
import IBankIdSignResponse from "../../../api/client/models/IBankIdSignResponse"
import moment from "moment"
import ClientQuestionnaireService from "../../../services/ClientQuestionnaireService"
import { QRCodeSVG } from "qrcode.react"

interface VerificationBankIdProps {
	bankIdResponse: IBankIdSignResponse
	qrAuthCode: string
	signStart: Date
	cancel: () => void
	onSuccess: () => void
	onFail: () => void
}

interface VerificationBankIdState {
	qrAuthCode: string
	status: VerificationBankIdStatus
}

enum VerificationBankIdStatus {
	Pending,
	Success,
	Fail,
}

class VerificationBankId extends React.Component<VerificationBankIdProps, VerificationBankIdState> {
	constructor(props: VerificationBankIdProps) {
		super(props)

		const {
			qrAuthCode,
		} = this.props

		this.state = {
			qrAuthCode,
			status: VerificationBankIdStatus.Pending,
		}
	}

	componentDidMount(): void {
		if (this.props.signStart != undefined) {
			this.setState({
				status: VerificationBankIdStatus.Pending,
			}, () => {
				this.updateQr()
				this.collectSignature()

				if (DeviceHelper.isHandHeld()) {
					window.location.replace(this.generateBankIdLaunchUrl()) // window.open(this.generateBankIdLaunchUrl(), "_blank")
				}
			})
		}
	}

	generateBankIdLaunchUrl = () => {
		const {
			bankIdResponse,
		} = this.props
		
		if (!bankIdResponse.autoStartToken) {
			return ""
		}
		
		let url = ""

		if (DeviceHelper.isAndroid() || DeviceHelper.isIOS()) {
			url = "https://app.bankid.com/"
		}
		else {
			url = "bankid:///"
		}

		url += "?autostarttoken="
			+ bankIdResponse.autoStartToken
			+ "&redirect="

		if (DeviceHelper.isIOS()) {
			url += encodeURIComponent(window.location.href + "#bankidreturn")
		}

		return url
	}

	updateQr = () => {
		const {
			bankIdResponse,
			signStart,
		} = this.props
		const {
			status,
		} = this.state
		
		if (status != VerificationBankIdStatus.Pending) {
			return
		}
		
		ClientQuestionnaireService
			.getQrCode(bankIdResponse.orderRef)
			.then((x) => {
				this.setState({
					qrAuthCode: x as string,
				}, () => {
					setTimeout(this.updateQr, 967)
				})
			}).catch(e => {
			})
	}

	collectSignature = () => {
		const {
			bankIdResponse,
			signStart,
			onSuccess,
			onFail,
		} = this.props
		
		if (moment(new Date()).diff(signStart, "seconds") > 120) {
			this.setState({
				status: VerificationBankIdStatus.Fail,
			}, onFail)

			return
		}
		
		ClientQuestionnaireService
			.collectBankIdSignature(bankIdResponse.orderRef)
			.then((success) => {
				if (success) {
					this.setState({
						status: VerificationBankIdStatus.Success,
					}, onSuccess)
				}
				else {
					this.setState({
						status: VerificationBankIdStatus.Fail,
					}, onFail)
				}
			}).catch(e => {
				switch (e.status) {
					case 202:
						setTimeout(this.collectSignature, 2000)
						break
					default:
						this.setState({
							status: VerificationBankIdStatus.Fail,
						}, onFail)
						break
				}
				// switch (e.data) {
				// 	case "BankIdPending":
				// 		setTimeout(() => { collectSignature(orderRef) }, 2000)
				// 		break
				// 	case "BankIdValidationFail":
				// 	default:
				// 		setActiveStep(6)
				// 		break
				// }
			})
	}

	render() {
		const {
			cancel,
		} = this.props
		const {
			qrAuthCode,
		} = this.state
		
		return (
			<div
				style={{
					flex: 1,
					display: "flex",
					flexDirection: "column",
				}}
			>
				<span>Vill du öppna bankid på denna enhet så <a href={this.generateBankIdLaunchUrl()}>klicka här</a>.</span>
				<QRCodeSVG
					value={qrAuthCode ? qrAuthCode : ""}
					height="256"
					width="256"
					style={{
						margin: "auto",
						flex: 1,
						display: "flex",
						flexDirection: "column",
						// maxWidth: "66%",
						aspectRatio: 1,
						height: "70%",
					}}
				/>
				<div
					style={{
						margin: "25.5px auto 17px",
					}}
				>
					<Button
						fullWidth
						size="large"
						color="primary"
						variant="contained"
						onClick={cancel}
					>
						Avbryt
					</Button>
				</div>
			</div>
		)
	}
}

export default VerificationBankId
