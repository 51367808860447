import React from "react"
import {
	Button,
} from "@mui/material"
import IClientDetails from "../../../api/models/IClientDetails"
import SignatureType from "../../../api/client/models/SignatureType"
import ManualVerification from "./ManualVerification"
import VerificationBankId from "./VerificationBankId"
import ISignatureResponse from "../../../api/client/models/ISignatureResponse"
import IBankIdSignResponse from "../../../api/client/models/IBankIdSignResponse"
import ClientQuestionnaireService from "../../../services/ClientQuestionnaireService"
import { VerificationStep } from "./VerificationStep"
import IClientSubmitAnswer from "../../../api/client/models/IClientSubmitAnswer"

interface VerificationProps {
	sendOutKey: string
	answers: IClientSubmitAnswer[] // { question: IClientQuestion, answer?: IClientAnswer }[]
	signatureType?: SignatureType
	activeVerificationStep: VerificationStep
	setActiveVerificationStep: (step: VerificationStep) => void
	// isLoading: boolean
	// setIsLoading: (isLoading: boolean) => void
	onSuccess: () => void
	onFail: (errorCode?: string) => void
}

interface VerificationState {
	signatureType?: SignatureType
	signatureTypeLocked: boolean
	bankIdResponse?: IBankIdSignResponse
	qrAuthCode?: string
	signStart?: Date
}

class Verification extends React.Component<VerificationProps, VerificationState> {
	constructor(props: VerificationProps) {
		super(props)

		const {
			signatureType,
		} = this.props

		this.state = {
			signatureType,
			signatureTypeLocked: signatureType != undefined,
		}
	}

	submit = (personInfo?: IClientDetails) => {
		const {
			sendOutKey,
			answers,
			setActiveVerificationStep,
			onSuccess,
			onFail,
			// setIsLoading,
		} = this.props
		const {
			signatureType,
		} = this.state

		if (signatureType == undefined) {
			return
		}

		if (sendOutKey == undefined) {
			return
		}

		if (answers.some(v => v.answerId == undefined && v.text == undefined)) {
			return
		}

		try {
			// setIsLoading(true)

			ClientQuestionnaireService
				.submit(sendOutKey, signatureType, answers, personInfo)
				.then(x => {
					const signResp = x as ISignatureResponse
					
					if (signResp.signatureType == SignatureType.BankId) {
						if (signResp.bankIdResponse != undefined
							&& signResp.bankIdResponse.orderRef != undefined
							&& signResp.bankIdResponse.autoStartToken != undefined
							&& signResp.qrAuthCode != undefined) {
							this.setState({
								signStart: new Date(),
								bankIdResponse: signResp.bankIdResponse,
								qrAuthCode: signResp.qrAuthCode,
							}, () => {
								// setIsLoading(false)
			
								setActiveVerificationStep(VerificationStep.BankIdPending)
							})
						}
						else {
							this.reset(VerificationStep.Select, () => { onFail("Unknown") })
						}
					}
					else {
						this.reset(VerificationStep.Done, onSuccess)
					}
				}).catch(e => {
					this.reset(VerificationStep.Select, () => { onFail(e.data) })
					// switch (e.data) {
					// 	case "AlreadySubmitted":
					// 	case "IncorrectKey":
					// 	case "MissingAnswers":
					// 	case "AnswerQuestionaireMismatch":
					// 		break
					// }
				})
		}
		catch (e) {
			// setIsLoading(false)
			console.log(e)
		}
	}

	handleBankIdCancel = () => {
		const {
			bankIdResponse,
		} = this.state
		const {
			// setIsLoading,
		} = this.props

		// setIsLoading(true)

		ClientQuestionnaireService.cancelSign(bankIdResponse!.orderRef).then(x => {
			this.reset(VerificationStep.Select)
		})
	}

	handleBankIdSuccess = () => {
		this.reset(VerificationStep.Done, this.props.onSuccess)
	}

	handleBankIdFail = () => {
		this.reset(VerificationStep.Select, this.props.onFail)
	}

	reset = (step: VerificationStep, callback?: () => void) => {
		const {
			signatureTypeLocked,
		} = this.state
		const {
			signatureType,
			setActiveVerificationStep,
			// setIsLoading,
		} = this.props

		this.setState({
			signStart: undefined,
			qrAuthCode: undefined,
			bankIdResponse: undefined,
			signatureType: signatureTypeLocked ? signatureType : undefined,
		}, () => {
			setActiveVerificationStep(step)
			// setIsLoading(false)

			if (callback != undefined) {
				callback()
			}
		})
	}

	renderSelectMethod = () => {
		const {
			setActiveVerificationStep,
		} = this.props

		return (
			<div>
				<div
					style={{
						margin: "1.5rem auto 1rem",
						maxWidth: "400px",
					}}
				>
					<Button
						fullWidth
						size="large"
						color="primary"
						variant="contained"
						onClick={() => {
							this.setState({
								signatureType: SignatureType.BankId,
							}, this.submit)
						}}>
						Mobilt BankID
					</Button>
				</div>
				<div
					style={{
						margin: "1rem auto",
						maxWidth: "400px",
					}}
				>
					<Button
						fullWidth
						size="large"
						color="primary"
						variant="text"
						onClick={() => {
							this.setState({
								signatureType: SignatureType.Manual,
							}, () => {
								setActiveVerificationStep(VerificationStep.ManualPending)
							})
						}}>
						Jag saknar e-ID
					</Button>
				</div>
			</div>
		)
	}

	render() {
		const {
			signatureType,
			activeVerificationStep,
		} = this.props
		const {
			signatureTypeLocked,
			bankIdResponse,
			qrAuthCode,
			signStart,
		} = this.state

		switch (activeVerificationStep) {
			case VerificationStep.ManualPending:
				return <ManualVerification
					submit={this.submit}
					cancel={() => {
						this.reset(VerificationStep.Select)
					}}
					hideChangeMethod={signatureTypeLocked}
				/>
			case VerificationStep.BankIdPending:
				if (bankIdResponse != undefined
					&& qrAuthCode != undefined
					&& signStart != undefined) {
					return <VerificationBankId
						bankIdResponse={bankIdResponse}
						qrAuthCode={qrAuthCode}
						signStart={signStart}
						cancel={this.handleBankIdCancel}
						onSuccess={this.handleBankIdSuccess}
						onFail={this.handleBankIdFail}
					/>
				}
				break
			// case VerificationStep.Select:
			// case VerificationStep.Done:
		}

		return this.renderSelectMethod()
	}
}

export default Verification
