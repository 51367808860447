import React from "react"
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef"
import ATable from "../../../components/ATable"
import ISendOutListItem from "../../../api/bo/models/sendout/ISendOutListItem"

const columns = [
	{
		field: "description",
		headerName: "Etikett",
		flex: 5,
	},
	{
		field: "key",
		headerName: "Nyckel",
		flex: 1,
	},
	// {
	// 	field: "caseId",
	// 	headerName: "caseId",
	// },
	// {
	// 	field: "questionnaireId",
	// 	headerName: "Formulär-ID",
	// },
	{
		field: "questionnaireIdentifier",
		headerName: "Formulär",
		flex: 1,
	},
	{
		field: "signDate",
		headerName: "Besvarad",
		type: "datetime",
		flex: 1,
	},
	{
		field: "created",
		headerName: "Skapad",
		type: "datetime",
		flex: 1,
	},
] as GridColDef<ISendOutListItem>[]

interface SendOutListProps {
	sendOuts: ISendOutListItem[]
	onSelectionChange: (
		selectedSendOuts: ISendOutListItem[],
		selectedSendOut?: ISendOutListItem
	) => void
	selected: string[]
}

interface SendOutListState {
}

class SendOutList extends React.Component<SendOutListProps, SendOutListState> {
	constructor(props: SendOutListProps) {
		super(props)

		this.state = {
		}
	}

	render() {
		const {
			sendOuts,
			onSelectionChange,
			selected,
		} = this.props

		return (
			<ATable
				columns={columns}
				rows={sendOuts}
				onSelectionChange={onSelectionChange}
				uniqueKey="key"
				selectedKeys={selected}
				sortBy={[{ field: "created", sort: "desc" }]}
			/>
		)
	}
}

export default SendOutList
