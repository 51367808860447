import CalculationApi from "../api/bo/CalculationApi"
import CalculationMethod from "../api/bo/models/questionnaire/CalculationMethod"
import ICalculation from "../api/bo/models/questionnaire/ICalculation"
import ICalculationPart from "../api/bo/models/questionnaire/ICalculationPart"
import ICalculationPartCutOff from "../api/bo/models/questionnaire/ICalculationPartCutOff"

export default class CalculationService {
	public static getAllForQuestionnaire(questionnaireId: string) {
		return CalculationApi.getAllForQuestionnaire(questionnaireId).then(calculations => {
			return calculations || []
		})
	}
	
	public static getOne(calculationId: string) {
		return CalculationApi.getOne(calculationId).then(calculation => {
			return calculation
		})
	}
	
	public static create(questionnaireId: string, identifier: string) {
		const calculation = {
			questionnaireId,
			identifier,
		} as ICalculation
		
		return CalculationApi.create(calculation).then(calculationId => {
			return calculationId
		})
	}
	
	public static update(calculationId: string, questionnaireId: string, identifier: string) {
		const calculation = {
			calculationId,
			questionnaireId,
			identifier,
		} as ICalculation
		
		return CalculationApi.update(calculationId, calculation).then(res => {
			return res
		})
	}
	
	public static delete(calculationId: string) {
		return CalculationApi.delete(calculationId).then(res => {
			return res
		})
	}
	
	public static createPart(calculationId: string,
		identifier: string,
		questionIds: string[],
		method: CalculationMethod,
		cutOffs: ICalculationPartCutOff[]) {
		const part = {
			calculationId,
			identifier,
			questionIds,
			method,
			cutOffs,
		} as ICalculationPart
		
		return CalculationApi.createPart(calculationId, part).then(partId => {
			return partId
		})
	}
	
	public static updatePart(partId: string,
		calculationId: string,
		identifier: string,
		questionIds: string[],
		method: CalculationMethod,
		cutOffs: ICalculationPartCutOff[]) {
		const part = {
			partId,
			calculationId,
			identifier,
			questionIds,
			method,
			cutOffs,
		} as ICalculationPart
			
		return CalculationApi.updatePart(partId, part).then(res => {
			return res
		})
	}
	
	public static deletePart(partId: string) {
		return CalculationApi.deletePart(partId).then(res => {
			return res
		})
	}
}
