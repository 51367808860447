import React from "react"
import {
	FormGroup,
} from "@mui/material"
import ICalculation from "../../../../api/bo/models/questionnaire/ICalculation"
import Typer from "../../../../components/form/Typer"

interface CalculationBaseProps {
	calculation?: ICalculation
	setIdentifier: (identifier: string) => void
	identifier: string
}

interface CalculationBaseState {
}

class CalculationBase extends React.Component<CalculationBaseProps, CalculationBaseState> {
	constructor(props: CalculationBaseProps) {
		super(props)
	}

	render() {
		const {
			calculation,
			identifier,
			setIdentifier,
		} = this.props
		if (!calculation) {
			return null
		}

		return (
			<FormGroup>
				<Typer
					label="Etikett"
					type="text"
					value={identifier}
					onTyped={(val) => {
						setIdentifier(val)
					}}
					description="För att kunna skilja på dem."
				/>
			</FormGroup>
		)
	}
}

export default CalculationBase
