import React, { ChangeEvent } from "react"
import SendOutService from "../../../services/SendOutService"
import ISimpleQuestionnaire from "../../../api/bo/models/questionnaire/ISimpleQuestionnaire"
import Typer from "../../../components/form/Typer"
import Selecter from "../../../components/form/Selecter"
import APopForm from "../../../components/APopForm"
import IReceiverType from "../../../api/bo/models/receivertype/IReceiverType"
import ManageSendOut from "./ManageSendOut"

interface CreateSendOutProps {
	caseId: string
	questionnaires: ISimpleQuestionnaire[]
	receiverTypes: IReceiverType[]
	onCreated: (id: string) => void
	onCancel: () => void
	showDialog: boolean
}
interface CreateSendOutState {
	description: string
	selectedQuestionnaireId: string
	selectedReceiverTypeId?: string
	descriptionValid: boolean
}

class CreateSendOut extends React.Component<CreateSendOutProps, CreateSendOutState> {
	constructor(props: CreateSendOutProps) {
		super(props)

		const {
			questionnaires,
		} = this.props

		this.state = {
			description: "",
			selectedQuestionnaireId: questionnaires.length ? questionnaires[0].questionnaireId : "",
			selectedReceiverTypeId: undefined,
			descriptionValid: false,
		}
	}

	createSendOut = () => {
		const {
			description,
			selectedQuestionnaireId,
			selectedReceiverTypeId,
		} = this.state
		const {
			caseId,
			onCreated,
		} = this.props

		if (!selectedQuestionnaireId) {
			return
		}

		SendOutService.create(
			caseId,
			selectedQuestionnaireId,
			description,
			selectedReceiverTypeId,
			undefined,
		).then(key => {
			if (key !== undefined) {
				this.setState({
					description: "",
					selectedQuestionnaireId: "",
				}, () => { onCreated(key) })
			}
		})
	}

	cancel = () => {
		const {
			onCancel,
		} = this.props

		this.setState({
			description: "",
			selectedQuestionnaireId: "",
			descriptionValid: false,
		}, onCancel)
	}

	render() {
		const {
			description,
			selectedQuestionnaireId,
			selectedReceiverTypeId,
			descriptionValid,
		} = this.state
		const {
			questionnaires,
			receiverTypes,
			showDialog,
		} = this.props

		const options = questionnaires.map(x => {
			return {
				value: x.questionnaireId,
				label: x.identifier + " (" + x.displayName + ")",
			}
		})

		options.splice(0, 0, {
			value: "",
			label: "välj formulär",
		})

		return (
			<APopForm
				title="Ny förfrågan"
				show={showDialog}
				onSubmit={this.createSendOut}
				submitDisabled={!description.length || !selectedQuestionnaireId}
				onClose={this.cancel}
			>
				<ManageSendOut
					description={description}
					setDescription={(value: string) => {
						this.setState({
							description: value,
						})
					}}
					receiverTypeId={selectedReceiverTypeId}
					receiverTypes={receiverTypes}
					setReceiverTypeId={(val) => {
						this.setState({
							selectedReceiverTypeId: val,
						})
					}}
				/>
				<Selecter
					value={selectedQuestionnaireId}
					label="Formulär"
					required
					displayEmpty
					onSelect={(val) => {
						this.setState({
							selectedQuestionnaireId: val as string,
						})
					}}
					options={options}
					description="Välj vilket formulär mottagaren ska fylla i."
				/>
			</APopForm>
		)
	}
}

export default CreateSendOut
