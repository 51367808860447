import { responsiveFontSizes } from "@mui/material";
import AuthApi from "../api/bo/AuthApi";
import { UserRole } from "../api/bo/models/auth/UserRole";

export default class AuthService {
	public static checkIfAuthenticated() {
		return AuthApi.isAuthenticated().then(() => {
			if (this.isAuthenticated()) {
				return true;
			}

			return false;
		});
	}
	
	public static authenticate(username: string, password: string) {
		return AuthApi.auth(username, password).then(resp => {
			if (!resp
				|| !resp.token) {
				// TODO: handle
				return false;
			}

			AuthService.setAuthed(resp.token);
			AuthService.setRole(resp.role);

			return true;
		});
	}

	public static signOut() {
		AuthService.setUnauthed();

		window.location.href = "/";
	}

	public static getAuthToken(): string | null {
		return localStorage.getItem("AuthToken");
	}

	public static isAuthenticated(): boolean {
		return this.getAuthToken() != null;
	}

	public static setRole(role: UserRole) {
		localStorage.setItem("UserRole", role);
	}

	public static getRole(): UserRole {
		const role = localStorage.getItem("UserRole");

		if (!role) {
			return UserRole.STANDARD;
		}

		return role as UserRole;
	}

	private static setAuthed(authToken: string) {
		localStorage.setItem("AuthToken", authToken);
	}

	private static setUnauthed() {
		localStorage.removeItem("AuthToken");
		localStorage.removeItem("UserRole");
	}
};
