import ICalculation from "./models/questionnaire/ICalculation"
import ICalculationPart from "./models/questionnaire/ICalculationPart"
import AdminBaseApi from "./AdminBaseApi"
import { RequestMethod } from "../RequestMethod"

export default class CalculationApi extends AdminBaseApi {
	protected static baseUri = "calculation"

	public static getAllForQuestionnaire(questionnaireId: string) {
		return this.fetch<ICalculation[]>("getall", RequestMethod.GET, undefined, [ questionnaireId ])
	}

	public static getOne(calculationId: string) {
		return this.fetch<ICalculation>(undefined, RequestMethod.GET, undefined, [ calculationId ])
	}

	public static create(calculation: ICalculation) {
		return this.fetch<string>(undefined, RequestMethod.POST, calculation)
	}

	public static update(calculationId: string, calculation: ICalculation) {
		return this.fetch<string>(undefined, RequestMethod.PUT, calculation, [ calculationId ])
	}

	public static delete(calculationId: string) {
		return this.fetch<string>(undefined, RequestMethod.DELETE, undefined, [ calculationId ])
	}

	public static createPart(calculationId: string, part: ICalculationPart) {
		return this.fetch<string>("part", RequestMethod.POST, part, [ calculationId ])
	}

	public static updatePart(partId: string, part: ICalculationPart) {
		return this.fetch<string>("part", RequestMethod.PUT, part, [ partId ])
	}

	public static deletePart(partId: string) {
		return this.fetch<string>("part", RequestMethod.DELETE, undefined, [ partId ])
	}
}
