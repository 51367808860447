import React, { useState, useEffect } from "react";
import {
	useParams,
	useNavigate,
} from "react-router-dom";
import AToolbar from "../../components/AToolbar";
import Wrapper from "../Wrapper";
import IUser from "../../api/bo/models/user/IUser";
import UserService from "../../services/UserService";
import { IAButtonProps } from "../../components/AButton";

const UserScreen = () => {
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState<boolean>(true);

	const { userId } = useParams<"userId">();

	const [user, setUser] = useState<IUser | undefined>(undefined);

	useEffect(() => {
		refresh();
	}, []);

	function refresh() {
		if (userId === undefined) {
			return;
		}

		setIsLoading(true);

		UserService.get(userId).then(x => {
			setUser(x as IUser);
			setIsLoading(false);
		});
	};

	const toolbarButtons: Array<IAButtonProps> = [
		// {
		// 	label: "ny förfrågan",
		// 	action: openCreateSendOutDialog,
		// 	icon: <AddRounded />,
		// 	type: "add",
		// },
	];

	return (
		<Wrapper
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			breadcrumbs={[
				{
					label: "start",
					path: "/"
				},
				{
					label: "användare",
					path: "/users",
				},
				{
					label: user !== undefined
						? user.username
						: "...",
				}
			]}
		>
			<AToolbar
				buttons={toolbarButtons}
			/>
		</Wrapper>
	);
}

export default UserScreen;
