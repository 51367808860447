import { ReactNode } from "react"
import APop, { IAPopProps } from "./APop"
import AForm from "./AForm"
import { Dialog } from "@mui/material"

export interface IAPopFormProps extends IAPopProps {
	onSubmit: () => void
	onClose: () => void
	children: ReactNode
}

class APopForm extends APop<IAPopFormProps> {
	constructor(props: IAPopFormProps) {
		super(props)
	}

	render() {
		const {
			show,
			onClose,
			fullScreen,
			closeByButtonOnly,
			style,
		} = this.props

		return <Dialog
			open={show}
			onClose={!closeByButtonOnly ? onClose : undefined}
			fullScreen={fullScreen}
			className="dialog"
			style={{...style}}
		>
			<AForm
				onSubmit={this.props.onSubmit}
			>
			{
				this.renderTitle()
			}
			{
				this.renderContent()
			}
			{
				this.renderActions()
			}
			</AForm>
		</Dialog>
	}
}

export default APopForm
