import React from "react"
import {
	FormGroup,
} from "@mui/material"

import EditAnswer from "./EditAnswer"
import AToolbar from "../../../components/AToolbar"
import IAnswer from "../../../api/bo/models/questionnaire/IAnswer"
import IQuestion from "../../../api/bo/models/questionnaire/IQuestion"
import Typer from "../../../components/form/Typer"
import Selecter from "../../../components/form/Selecter"
import Lister from "../../../components/lister/Lister"
import QuestionType from "../../../api/bo/models/questionnaire/QuestionType"
import { IAButtonProps } from "../../../components/AButton"

interface ManageQuestionProps {
	isAdding?: boolean
	question: IQuestion
	questions: IQuestion[]
	moveQuestion: (fromOrder: number, toOrder: number) => void
	deleteQuestion?: (order: number) => void
	setQuestionType: (order: number, type: QuestionType) => void
	setQuestionText: (order: number, text: string) => void
	setUseAnswersFrom: (order: number, questionId?: string) => void
	setAnswers: (order: number, answers: IAnswer[]) => void
}

interface ManageQuestionState {
	activeAnswer?: IAnswer
	activeSection: number
}

class ManageQuestion extends React.Component<ManageQuestionProps, ManageQuestionState> {
	constructor(props: ManageQuestionProps) {
		super(props)

		this.state = {
			activeAnswer: undefined,
			activeSection: 0,
		}
	}

	addAnswer = () => {
		this.setState({
			activeAnswer: {} as IAnswer,
		})
	}

	setAnswer = (answer: IAnswer) => {
		const {
			question,
			setAnswers,
		} = this.props

		const updatedAnswers = question.answers

		if (!answer.order) {
			answer.order = updatedAnswers.length + 1

			updatedAnswers.push(answer)
		}
		else {
			for (let i = 0; i < updatedAnswers.length; i++) {
				if (updatedAnswers[i].order == answer.order) {
					updatedAnswers[i].text = answer.text
					updatedAnswers[i].value = answer.value
				}
			}
		}

		setAnswers(question.order, updatedAnswers)

		this.setState({
			activeAnswer: undefined,
		})
	}

	deleteAnswer = (order: number) => {
		const {
			question,
			setAnswers,
		} = this.props

		const updatedAnswers = question.answers.filter(q => q.order != order)

		for (let i = 0; i < updatedAnswers.length; i++) {
			updatedAnswers[i].order = i + 1
		}

		setAnswers(question.order, updatedAnswers)
	}

	updateOrderOfAnswers = (answers: IAnswer[]) => {
		const {
			question,
			setAnswers,
		} = this.props

		for (let i = 0; i < answers.length; i++) {
			answers[i].order = i + 1
		}
		
		const orderedAnswers = answers.map(a => {
			return a
		})

		setAnswers(question.order, orderedAnswers)
	}

	renderAnswers = () => {
		const {
			question,
		} = this.props
		const {
			activeAnswer,
		} = this.state

		return <Lister<IAnswer>
			items={question.answers}
			keyExtractor={(item) => item.order.toString()}
			selectedKeys={
				activeAnswer != undefined
					&& activeAnswer.order != undefined
				? [activeAnswer.order.toString()]
				: []
			}
			labelExtractor={(item) => "#" + item.order + ": " + item.text + " (värde: " + item.value.toString() + ")"}
			onSort={this.updateOrderOfAnswers}
			onClick={(item) => {
				this.setState({
					activeAnswer: item,
				})
			}}
			onDelete={(item) => {
				this.deleteAnswer(item.order)
			}}
			hideCheckbox
			singleSelect
		/>
	}

	render() {
		const {
			isAdding,
			question,
			questions,
			moveQuestion,
			setQuestionType,
			setQuestionText,
			setUseAnswersFrom,
		} = this.props
		const {
			activeAnswer,
		} = this.state

		const otherQuestionsWithAnswers = questions.filter(q =>
			q.questionId != undefined
			&& q.type != QuestionType.FreeText
			&& q.derivedAnswersId == undefined
			&& q.questionId != question.questionId
		)
		
		let otherQuestionsUsingTheseAnswers: IQuestion[] = []
		
		if (question.questionId != undefined) {
			otherQuestionsUsingTheseAnswers = questions.filter(q =>
				q.derivedAnswersId == question.questionId
			)
		}

		// const baseToolbarButtons: IAButtonProps[] = [
		// 	{
		// 		label: "radera fråga",
		// 		color: "secondary",
		// 		variant: "text",
		// 		action: deleteQuestion,
		// 	},
		// 	{
		// 		label: "skapa kopia av fråga",
		// 		action: () => {
		// 		},
		// 		disabled: true,
		// 	},
		// ]
		const questionTypeToolbar: IAButtonProps[] = [
			{
				label: "fritext",
				action: () => {
					setQuestionType(question.order, QuestionType.FreeText)
				},
				type: "default",
				active: question.type == QuestionType.FreeText,
			},
			{
				label: "enval",
				action: () => {
					setQuestionType(question.order, QuestionType.SingleChoice)
				},
				type: "default",
				active: question.type == QuestionType.SingleChoice,
			},
		]
		const singleChoiceToolbar: IAButtonProps[] = [
			{
				label: "ärv svar",
				action: () => {
					// TODO: break out
					if (!otherQuestionsWithAnswers.length) {
						alert("Det finns inga andra frågor som har några egna svar. Lägg till svar på en annan fråga först för att kunna återanvända dess svar för denna fråga. Har du lagt till en ny fråga med egna svarsalternativ så testa att spara formuläret först så ska det nog gå sedan.")
					}
					else if (otherQuestionsUsingTheseAnswers.length) {
						const otherQuestions: string[] = []

						for (let i in otherQuestionsUsingTheseAnswers) {
							otherQuestions.push("#" + otherQuestionsUsingTheseAnswers[i].order)
						}

						alert("Det finns andra frågor som använder den här frågans svar. Ta bort arvet från de frågorna innan du raderar eller gör om svarstypen för denna frågan. Frågorna i fråga är: " + otherQuestions.join(", "))
					}
					else {
						setUseAnswersFrom(
							question.order,
							otherQuestionsWithAnswers[otherQuestionsWithAnswers.length - 1].questionId
						)
					}
				},
				// group: "answers",
				type: "default",
				active: question.derivedAnswersId != undefined,
			},
			{
				label: "egna svar",
				action: () => {
					setUseAnswersFrom(question.order, undefined)
				},
				// group: "answers",
				type: "default",
				active: question.derivedAnswersId == undefined,
			},
			{
				label: "skapa svar",
				type: "add",
				action: this.addAnswer,
				disabled: question.derivedAnswersId != undefined,
			},
		]

		const placeQuestionOptions = questions.map(q => {
			return {
				key: "placequestion-" + q.questionId,
				value: q.order.toString(),
				disabled: q.order == question.order,
				label: "#" + q.order,
			}
		})

		if (isAdding) {
			placeQuestionOptions.push({
				key: "placequestion-last",
				value: (questions.length + 1).toString(),
				disabled: question.order == questions.length + 1,
				label: "sist",
			})
		}

		return (
			<div
				// style={{
				// 	margin: "0",
				// }}
			>
				{/* <Toolbar
					buttons={toolbarButtons}
				/> */}
				{
					<FormGroup>
						<Typer
							multiline
							required
							label="Text"
							type="text"
							value={question.text}
							onTyped={(val) => {
								setQuestionText(question.order, val)
							}}
							description="Skriv frågeställningen här så som den ska presenteras för mottagaren."
						/>
						<Selecter
							label="Placera fråga"
							value={question.order.toString()}
							options={placeQuestionOptions}
							onSelect={(val) => {
								try {
									moveQuestion(
										question.order,
										parseInt(val as string),
									)
								}
								catch (e) {}
							}}
							description="Välj i vilken ordning frågan ska ligga i formuläret."
							// style={{
							// 	margin: "17px " + horMargin,
							// }}
						/>
						{/* {
							!isAdding
								? <Toolbar
									buttons={baseToolbarButtons}
								/>
								: null
						} */}
					</FormGroup>
				}
				{
					<FormGroup>
						<AToolbar
							buttons={questionTypeToolbar}
						/>
					{
						question.type == QuestionType.SingleChoice
						? <>
						<AToolbar
							buttons={singleChoiceToolbar}
						/>
						{
							question.derivedAnswersId != undefined
							? <Selecter
								label="Välj fråga att använda svar från"
								value={question.derivedAnswersId}
								options={otherQuestionsWithAnswers.map(q => {
									return {
										key: "useanswersfrom-" + q.questionId!,
										value: q.questionId!,
										label: "#" + q.order,
									}
								})}
								onSelect={(val) => {
									setUseAnswersFrom(question.order, !val.length
										? undefined
										: val as string)
								}}
								description="Om denna fråga ska använda samma svarsalternativ som någon annan fråga så välj den här."
								// style={{
								// 	display: question.derivedAnswersId
								// 		? "block"
								// 		: "none",
								// }}
								// style={{
								// 	margin: "17px " + horMargin,
								// }}
							/>
							: this.renderAnswers()
						}
						</>
						: undefined
					}
					</FormGroup>
				}
				{
					activeAnswer != undefined
					&& <EditAnswer
						closeEditAnswerDialog={() => {
							this.setState({
								activeAnswer: undefined,
							})
						}}
						answer={activeAnswer}
						updateAnswer={this.setAnswer}
					/>
				}
			</div>
		)
	}
}

export default ManageQuestion
