import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import moment from "moment";
import React from "react";
import ICase from "../../../api/bo/models/case/ICase";
import IUser from "../../../api/bo/models/user/IUser";
import ATable from "../../../components/ATable";

const columns = [
	{
		field: "username",
		headerName: "Login",
		flex: 1,
	},
	{
		field: "role",
		headerName: "Roll",
		flex: 1,
	},
	{
		field: "createdAt",
		headerName: "Skapad",
		type: "date",
		// valueGetter: (params) => { return moment(params.value).format("YYYY-MM-DD"); },
		flex: 1,
	},
] as GridColDef<IUser>[];

interface UserListProps {
	users: IUser[];
	onSelectionChange: (selectedUsers: IUser[], selectedUser?: IUser) => void;
	selectedIds: string[];
};

interface UserListState {
}

class UserList extends React.Component<UserListProps, UserListState> {
	constructor(props: UserListProps) {
		super(props);

		this.state = {
		};
	}

	render() {
		const {
			users,
			onSelectionChange,
			selectedIds,
		} = this.props;
		// const rows = cases
		// 	// .filter(f => { if (showClosed || f.status !== CASE_STATUS.CLOSED) { return f; } })
		// 	.map(f => {
		// 		return f;
		// 	});

		return (
			<ATable
				columns={columns}
				rows={users}
				onSelectionChange={onSelectionChange}
				uniqueKey="userId"
				selectedKeys={selectedIds}
				sortBy={[{ field: "createdAt", sort: "desc" }]}
			/>
		);
	}
}

export default UserList;