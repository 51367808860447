import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef"
import React from "react"
import ICase from "../../../api/bo/models/case/ICase"
import ATable from "../../../components/ATable"

const columns = [
	// {
	// 	field: "caseId",
	// 	headerName: "ID",
	// 	flex: 1,
	// },
	{
		field: "identifier",
		headerName: "Etikett",
		flex: 1,
	},
	{
		field: "dateOfBirth",
		headerName: "Födelsedatum",
		type: "date",
		flex: 1,
	},
	{
		field: "createdAt",
		headerName: "Skapad",
		type: "date",
		// valueGetter: (params) => { return moment(params.value).format("YYYY-MM-DD") },
		flex: 1,
	},
] as GridColDef<ICase>[]

interface CaseListProps {
	cases: ICase[]
	onSelectionChange: (selectedCases: ICase[], selectedCase?: ICase) => void
	selectedIds: string[]
}

interface CaseListState {
}

class CaseList extends React.Component<CaseListProps, CaseListState> {
	constructor(props: CaseListProps) {
		super(props)

		this.state = {
		}
	}

	render() {
		const {
			cases,
			onSelectionChange,
			selectedIds,
		} = this.props
		// const rows = cases
		// 	// .filter(f => { if (showClosed || f.status !== CASE_STATUS.CLOSED) { return f } })
		// 	.map(f => {
		// 		return f
		// 	})

		return (
			<ATable
				columns={columns}
				rows={cases}
				onSelectionChange={onSelectionChange}
				uniqueKey="caseId"
				selectedKeys={selectedIds}
				sortBy={[{ field: "createdAt", sort: "desc" }]}
			/>
		)
	}
}

export default CaseList