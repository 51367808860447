import APop, { IAPopProps } from "./APop"

export interface IAPopConfirmProps extends IAPopProps {
	message: string
	onConfirm: () => void
	onCancel: () => void
}

class APopConfirm extends APop<IAPopConfirmProps> {
	constructor(props: IAPopConfirmProps) {
		super(props)
	}
}

export default APopConfirm
