import ReceiverTypeApi from "../api/bo/ReceiverTypeApi"
import IReceiverType from "../api/bo/models/receivertype/IReceiverType"

export default class ReceiverTypeService {
	public static getAll() {
		return ReceiverTypeApi.getAll().then(x => {
			return x || []
		})
	}
	
	public static getOne(receiverTypeId: string) {
		return ReceiverTypeApi.getOne(receiverTypeId).then(x => {
			return x
		})
	}
	
	public static create(identifier: string) {
		const body = {
			identifier,
		} as IReceiverType
		
		return ReceiverTypeApi.create(body).then(receiverTypeId => {
			return receiverTypeId
		})
	}
	
	public static update(receiverTypeId: string, identifier: string) {
		const body = {
			identifier,
		} as IReceiverType
		
		return ReceiverTypeApi.update(receiverTypeId, body).then(x => {
			return x
		})
	}
	
	public static delete(receiverTypeId: string) {
		return ReceiverTypeApi.delete(receiverTypeId).then(x => {
			return x
		})
	}
}
