import {
	DashboardRounded,
	AssignmentIndRounded,
	FormatListBulletedRounded,
	SettingsRounded,
	HomeRounded,
	PeopleRounded,
	BorderColorRounded,
	BarChartRounded,
	VerifiedUserRounded,
	AccountBalanceRounded,
	SupportAgentRounded,
	Settings,
	Public,
	Home,
	AddRounded,
	EditRounded,
	GroupAddRounded,
	GroupRounded,
	FunctionsRounded,
} from "@mui/icons-material"
import IPage from "./IPage"
import StartScreen from "../screens/start/StartScreen"
import CaseOverviewScreen from "../screens/caseoverview/CaseOverviewScreen"
import CaseScreen from "../screens/case/CaseScreen"
import AnswersScreen from "../screens/case/AnswersScreen"
import QuestionnaireOverviewScreen from "../screens/questionnaire/QuestionnaireOverviewScreen"
import QuestionnaireScreen from "../screens/questionnaire/QuestionnaireScreen"
import CalculationOverviewScreen from "../screens/questionnaire/calculations/CalculationOverviewScreen"
import CalculationScreen from "../screens/questionnaire/calculations/CalculationScreen"
import SettingsScreen from "../screens/settings/SettingsScreen"
import UserOverviewScreen from "../screens/useroverview/UserOverviewScreen"
import UserScreen from "../screens/user/UserScreen"
import { UserRole } from "../api/bo/models/auth/UserRole"
import AnswerScreen from "../screens/answer/AnswerScreen"

const PAGES = [
	{
		name: "Översikt",
		path: "/",
		screen: <StartScreen />,
		icon: <DashboardRounded />,
	} as IPage,
	{
		name: "Ärenden",
		path: "/cases",
		screen: <CaseOverviewScreen />,
		icon: <AssignmentIndRounded />,
		sub: [
			{
				name: "Skapa",
				path: "/case",
				screen: <CaseScreen />,
				icon: <AddRounded />,
			} as IPage,
			{
				name: "Hantera",
				path: "/case/:caseId",
				screen: <CaseScreen />,
				icon: <EditRounded />,
				sub: [
					{
						name: "Inkomna svar",
						path: "/case/:caseId/answers/:sendOutId",
						screen: <AnswersScreen />,
					} as IPage,
				],
			} as IPage,
		],
	} as IPage,
	{
		name: "Formulär",
		path: "/questionnaires",
		screen: <QuestionnaireOverviewScreen />,
		icon: <FormatListBulletedRounded />,
		sub: [
			{
				name: "Skapa formulär",
				path: "/questionnaire",
				screen: <QuestionnaireScreen />,
				icon: <AddRounded />,
			} as IPage,
			{
				name: "Hantera formulär",
				path: "/questionnaire/:questionnaireId",
				screen: <QuestionnaireScreen />,
				icon: <EditRounded />,
				sub: [
					{
						name: "Beräkningar",
						path: "/questionnaire/:questionnaireId/calculations",
						screen: <CalculationOverviewScreen />,
						icon: <FunctionsRounded />,
					} as IPage,
					{
						name: "Skapa beräkning",
						path: "/questionnaire/:questionnaireId/calculation",
						screen: <CalculationScreen />,
						icon: <AddRounded />,
					} as IPage,
					{
						name: "Hantera",
						path: "/questionnaire/:questionnaireId/calculation/:calculationId",
						screen: <CalculationScreen />,
						icon: <EditRounded />,
					} as IPage,
				],
			} as IPage,
		],
	} as IPage,
	{
		name: "Inställningar",
		path: "/settings",
		screen: <SettingsScreen />,
		icon: <SettingsRounded />,
		sub: [
			{
				name: "Mottagartyper",
				path: "/settings/receivertypes",
				screen: <SettingsScreen
					section={2}
				/>,
				icon: <GroupRounded />,
			} as IPage,
		],
	} as IPage,
	{
		name: "Användare",
		path: "/users",
		screen: <UserOverviewScreen />,
		icon: <PeopleRounded />,
		allowedRoles: [ UserRole.ADMIN ],
		sub: [
			{
				name: "Skapa",
				path: "/user",
				screen: <CalculationScreen />,
				icon: <AddRounded />,
			} as IPage,
			{
				name: "Hantera",
				path: "/user/:userId",
				screen: <UserScreen />,
				icon: <EditRounded />,
			} as IPage,
		],
	} as IPage,
	// CLIENT
	{
		name: "Svara",
		path: "/q/:key",
		screen: <AnswerScreen />,
		allowAnonymous: true,
		hideInMenu: true,
	} as IPage,
] as IPage[]

export {
	PAGES,
}
