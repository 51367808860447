import React, { useState, useEffect } from "react"
import {
	useNavigate,
} from "react-router-dom"
import {
	EditRounded,
	AddRounded,
	LockOpenRounded,
	LockRounded,
	FunctionsRounded,
	DeleteForeverRounded
} from "@mui/icons-material"
import AToolbar from "../../components/AToolbar"

import QuestionnaireManagementService from "../../services/QuestionnaireManagementService"
import ISimpleQuestionnaire from "../../api/bo/models/questionnaire/ISimpleQuestionnaire"

import QuestionnaireList from "./components/QuestionnaireList"
import Wrapper from "../Wrapper"
import { IAButtonProps } from "../../components/AButton"

const QuestionnaireOverviewScreen = () => {
	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState<boolean>(true)

	const [questionnaires, setQuestionnaires] = useState<Array<ISimpleQuestionnaire>>([])
	const [selectedQuestionnaires, setSelectedQuestionnaires] = useState<Array<ISimpleQuestionnaire>>([])

	useEffect(() => {
		refresh()
	}, [])
	
	function refresh() {
		setIsLoading(true)

		QuestionnaireManagementService.getAll().then(res => {
			if (Array.isArray(res)) {
				setQuestionnaires(res)
			}

			setIsLoading(false)
		})
	}

	const oneSelected = selectedQuestionnaires.length === 1
	const selectedQuestionnaire = oneSelected ? selectedQuestionnaires[0] : undefined

	function goToCalculations() {
		if (!oneSelected) {
			return
		}

		navigate("/questionnaire/" + selectedQuestionnaires[0].questionnaireId + "/calculations", {
			replace: false,
		})
	}

	function goToSelectedQuestionnaire() {
		if (!oneSelected) {
			return
		}

		navigate("/questionnaire/" + selectedQuestionnaires[0].questionnaireId, {
			replace: false,
		})
	}

	function lockQuestionnaire() {
		if (selectedQuestionnaire == undefined) {
			return
		}
		
		if (window.confirm("När du låst ett formulär kommer du inte längre ha möjlighet att ändra det. Du kan heller inte låsa upp det igen. Är du säker?")) {
			setIsLoading(true)

			QuestionnaireManagementService.lock(selectedQuestionnaire.questionnaireId)
				.then(refresh)
		}
	}

	function deleteQuestionnaire() {
		if (selectedQuestionnaire == undefined) {
			return
		}
		
		if (window.confirm("Är du säker på att du vill radera detta formulär?")) {
			setIsLoading(true)

			QuestionnaireManagementService.delete(selectedQuestionnaire.questionnaireId)
				.then(refresh)
		}
	}
	// const selectedIsClosed = oneSelected && selectedQuestionnaires[0].status === CaseStatus.CLOSED

	const toolbarButtons: Array<IAButtonProps> = [
		{
			label: "skapa nytt",
			action: () => {
				navigate("/questionnaire")
			},
			icon: <AddRounded />,
			type: "add",
		},
		{
			label: "hantera",
			action: goToSelectedQuestionnaire,
			icon: <EditRounded />,
			disabled: !oneSelected,
			type: "default",
		},
		{
			label: "beräkningar",
			action: goToCalculations,
			icon: <FunctionsRounded />,
			disabled: !oneSelected, // || selectedCaseIsClosed,
			type: "default",
		},
		{
			label: selectedQuestionnaire?.isLocked ? "låst" : "lås",
			action: lockQuestionnaire,
			icon: <LockRounded />,
			disabled: !oneSelected || selectedQuestionnaire?.isLocked,
			type: "lock",
		},
		{
			label: "radera",
			action: deleteQuestionnaire,
			icon: <DeleteForeverRounded />,
			disabled: !oneSelected,
			type: "delete",
		},
	]

	return (
		<Wrapper
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			breadcrumbs={[
				{
					label: "start",
					path: "/"
				},
				{
					label: "formulär",
				}
			]}
		>
			<AToolbar
				buttons={toolbarButtons}
			/>
			<QuestionnaireList
				questionnaires={questionnaires.filter(q => { return !q.isHidden })}
				onSelectionChange={(selectedQuestionnaires: ISimpleQuestionnaire[]) => {
					setSelectedQuestionnaires(selectedQuestionnaires)
				}}
				selectedIds={selectedQuestionnaires.map(q => { return q.questionnaireId })}
			/>
		</Wrapper>
	)
}

export default QuestionnaireOverviewScreen
