import { RequestMethod } from "../RequestMethod";
import AdminBaseApi from "./AdminBaseApi";
import IReceiverType from "./models/receivertype/IReceiverType";

export default class ReceiverTypeApi extends AdminBaseApi {
	protected static baseUri = "receivertype"

	public static getAll() {
		return this.fetch<IReceiverType[]>(undefined, RequestMethod.GET)
	}

	public static getOne(receiverTypeId: string) {
		return this.fetch<IReceiverType>(undefined, RequestMethod.GET, undefined, [ receiverTypeId ])
	}

	public static create(receiverType: IReceiverType) {
		return this.fetch<string>(undefined, RequestMethod.POST, receiverType)
	}

	public static update(receiverTypeId: string, receiverType: IReceiverType) {
		return this.fetch<string>(undefined, RequestMethod.PUT, receiverType, [ receiverTypeId ])
	}

	public static delete(receiverTypeId: string) {
		return this.fetch<string>("delete", RequestMethod.GET, undefined, [ receiverTypeId ])
	}
};
