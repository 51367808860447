import React, { useState, useEffect } from "react"
import {
	useParams,
	useNavigate,
} from "react-router-dom"
import CaseService from "../../services/CaseService"
import SendOutService from "../../services/SendOutService"
import QuestionnaireManagementService from "../../services/QuestionnaireManagementService"
import { AddRounded, VisibilityRounded, LinkRounded, DeleteRounded } from "@mui/icons-material"
import {
	Snackbar,
	DialogContentText,
	TextField,
	Button
} from "@mui/material"
import ICase from "../../api/bo/models/case/ICase"
import ISimpleQuestionnaire from "../../api/bo/models/questionnaire/ISimpleQuestionnaire"
import AToolbar from "../../components/AToolbar"
import Wrapper from "../Wrapper"
import SendOutList from "./components/SendOutList"
import CreateSendOut from "./components/CreateSendOut"
import APop from "../../components/APop"
import moment from "moment"
import { IAButtonProps } from "../../components/AButton"
import APopForm from "../../components/APopForm"
import ReceiverTypeService from "../../services/ReceiverTypeService"
import IReceiverType from "../../api/bo/models/receivertype/IReceiverType"
import AForm from "../../components/AForm"
import ManageCase from "./components/ManageCase"
import ICaseRequest from "../../api/bo/models/case/ICaseRequest"
import SaveStatus from "../../components/form/SaveStatus"
import APopConfirm from "../../components/APopConfirm"
import ISendOutListItem from "../../api/bo/models/sendout/ISendOutListItem"

const CaseScreen = () => {
	const navigate = useNavigate()

	const { caseId } = useParams<"caseId">()

	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [isInitialized, setIsInitialized] = useState<boolean>(false)
	const [saveState, setSaveState] = useState<SaveStatus>(SaveStatus.Saved)

	const [activeStep, setActiveStep] = useState<number>(1)

	const [theCase, setTheCase] = useState<ICase | undefined>(undefined)
	const [questionnaires, setQuestionnaires] = useState<ISimpleQuestionnaire[]>([])
	const [receiverTypes, setReceiverTypes] = useState<IReceiverType[]>([])
	const [selectedSendOuts, setSelectedSendOuts] = useState<Array<ISendOutListItem>>([])
	const [isCreateSendOutDialogOpen, setIsCreateSendOutDialogOpen] = useState<boolean>(false)
	const [isDeleteSendOutDialogOpen, setIsDeleteSendOutDialogOpen] = useState<boolean>(false)
	const [showLinkCopiedSnackbar, setShowLinkCopiedSnackbar] = useState<boolean>(false)
	const [showLinkCopyDialog, setShowLinkCopyDialog] = useState<boolean>(false)

	// const [draft, setDraft] = useState<ICaseRequest | undefined>(undefined)
	const [identifier, setIdentifier] = useState<string>("")
	const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>(undefined)

	useEffect(() => {
		refresh()
	}, [])

	useEffect(() => {
		if (theCase === undefined) {
			return
		}

		if (identifier !== theCase.identifier
			// date of birth in response is null, in local model undefined
			// find a way to ascertain it's undefined in response
			// make soft check in the meantime
			|| dateOfBirth != theCase.dateOfBirth) {
			setSaveState(SaveStatus.HasChanges)
		}
	}, [identifier, dateOfBirth])

	function refresh() {
		if (caseId === undefined) {
			return
		}

		setIsLoading(true)

		CaseService.get(caseId).then(c => {
			const caseResp = c as ICase;
			setTheCase(caseResp)

			if (!isInitialized) {
				setIdentifier(caseResp.identifier)
				setDateOfBirth(caseResp.dateOfBirth)
				setIsInitialized(true)
			}

			setIsLoading(false)
		})
	}

	const save = () => {
		if (caseId === undefined) {
			return
		}

		setIsLoading(true)
		setSaveState(SaveStatus.Saving)

		CaseService.update(caseId, identifier, dateOfBirth).then(c => {
			setSaveState(SaveStatus.Saved)
			refresh()
		})
		.catch((e) => {
			setIsLoading(false)
		})
	}

	const oneSelectedSendOut = selectedSendOuts.length === 1
	const selectedSendOut = oneSelectedSendOut ? selectedSendOuts[0] : undefined
	const selectedIsSigned = oneSelectedSendOut && selectedSendOuts[0].signDate != undefined

	function openCreateSendOutDialog() {
		setIsLoading(true)

		Promise.all([
			QuestionnaireManagementService.getAll(),
			ReceiverTypeService.getAll(),
		]).then(values => {
			const questionnairesResponse = values[0]
			const receiverTypesResponse = values[1]

			if (!questionnairesResponse || !receiverTypesResponse) {
				return;
			}

			setQuestionnaires(questionnairesResponse)
			setReceiverTypes(receiverTypesResponse)

			setIsLoading(false)
			setIsCreateSendOutDialogOpen(true)
		});
	}

	function generateDirectLink() {
		if (selectedSendOut === undefined) {
			return ""
		}

		return window.location.protocol + "//" + window.location.host + "/q/" + selectedSendOut.key
	}

	function copySendOutLinkToClipboard() {
		if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
			setShowLinkCopiedSnackbar(true)

			return navigator.clipboard.writeText(generateDirectLink())
		}
		else {
			return Promise.reject("The Clipboard API is not available.")
		}
	}

	function goQuestionnaireResult() {
		if (!selectedIsSigned) {
			return
		}

		navigate("/case/" + caseId + "/answers/" + selectedSendOuts[0].key, {
			replace: false,
		})
	}

	function deleteSelected() {
		if (!selectedSendOut) {
			return
		}

		setIsLoading(true)

		SendOutService.delete(selectedSendOut.key).then(() => {
			refresh()
		})
		.finally(() => {
			setIsLoading(false)
			setIsDeleteSendOutDialogOpen(false)
		})
	}

	const toolbarButtons: Array<IAButtonProps> = [
		{
			label: "basinfo",
			action: () => {
				setActiveStep(1)
			},
			type: "default",
			active: activeStep === 1,
		},
		{
			label: "utskick",
			action: () => {
				setActiveStep(2)
			},
			type: "default",
			active: activeStep === 2,
		},
		{
			label: "nytt utskick",
			action: openCreateSendOutDialog,
			icon: <AddRounded />,
			type: "add",
		},
		{
			label: "visa svar",
			action: goQuestionnaireResult,
			icon: <VisibilityRounded />,
			disabled: !selectedIsSigned,
			type: "default",
			hidden: activeStep !== 2,
		},
		{
			label: "hämta direktlänk",
			action: () => {
				setShowLinkCopyDialog(true)
			},
			icon: <LinkRounded />,
			disabled: !oneSelectedSendOut || selectedIsSigned,
			type: "download",
			hidden: activeStep !== 2,
		},
		{
			label: saveState === SaveStatus.Saved
				? "allting sparat"
				: saveState === SaveStatus.Saving
					? "sparar ..."
					: "spara ändringar",
			action: save,
			type: "save",
			disabled: saveState !== SaveStatus.HasChanges,
			hidden: activeStep !== 1,
		},
		{
			label: "radera",
			action: () => {
				setIsDeleteSendOutDialogOpen(true)
			},
			icon: <DeleteRounded />,
			disabled: !oneSelectedSendOut,
			type: "delete",
			hidden: activeStep !== 2,
		},
	]

	function renderSection() {
		switch (activeStep) {
			case 1:
				return <ManageCase
					identifier={identifier}
					dateOfBirth={dateOfBirth}
					setIdentifier={setIdentifier}
					setDateOfBirth={(v) => { console.log("setting dob: " + v?.toString()); setDateOfBirth(v) }}
				/>
			case 2:
				if (theCase === undefined) {
					return
				}

				return <SendOutList
					sendOuts={theCase.sendOuts}
					onSelectionChange={(
							selectedSendOuts: ISendOutListItem[],
							selectedSendOut?: ISendOutListItem
						) => {
							setSelectedSendOuts(selectedSendOuts)
						}
					}
					selected={selectedSendOuts.map(s => { return s.key })}
				/>
		}
	}

	return (
		<Wrapper
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			// showBack
			breadcrumbs={[
				{
					label: "start",
					path: "/"
				},
				{
					label: "ärenden",
					path: "/cases",
				},
				{
					label: theCase !== undefined
						? theCase.identifier
							+ (theCase.dateOfBirth
								? " " + moment(theCase.dateOfBirth).format("YYYY-MM-DD")
								: ""
							)
						: "...",
				}
			]}
		>
			<AToolbar
				buttons={toolbarButtons}
			/>
		{
			renderSection()
		}
		{
			caseId !== undefined
			&& <CreateSendOut
				caseId={caseId}
				questionnaires={questionnaires}
				receiverTypes={receiverTypes}
				showDialog={isCreateSendOutDialogOpen}
				onCancel={() => {
					setIsCreateSendOutDialogOpen(false)
				}}
				onCreated={(key: string) => {
					refresh()
					setIsCreateSendOutDialogOpen(false)
				}}
			/>
		}
			<Snackbar
				open={showLinkCopiedSnackbar}
				autoHideDuration={6000}
				onClose={() => {
					setShowLinkCopiedSnackbar(false)
				}}
				message="Länk kopierad till urklipp!"
			/>
			<APopForm
				title="Ny förfrågan"
				show={showLinkCopyDialog}
				onSubmit={() => {
					copySendOutLinkToClipboard()
					setShowLinkCopyDialog(false)
				}}
				onClose={() => {
					setShowLinkCopyDialog(false)
				}}
				submitLabel="kopiera till urklipp"
			>
				<DialogContentText>
					Här kan du hämta direktlänk som kan skickas till den som ska besvara formuläret.
				</DialogContentText>
				<TextField
					autoFocus
					margin="dense"
					label="Länk"
					type="url"
					fullWidth
					variant="standard"
					value={generateDirectLink()}
					InputProps={{
						readOnly: true,
						autoFocus: true,
					}}
					onFocus={event => {
						  event.target.select()
					}}
					// contentEditable="false"
				/>
			</APopForm>
			<APopConfirm
				title="Radera utskick"
				message="Är du säker på att du vill radera detta utskick?"
				onCancel={() => {
					setIsDeleteSendOutDialogOpen(false)
				}}
				onConfirm={deleteSelected}
				show={isDeleteSendOutDialogOpen}
			/>
		</Wrapper>
	)
}

export default CaseScreen
