export enum AnswerStep {
	LoadingQuestionnaire,
	Intro,
	Questionnaire,
	Signing,
	Success,
	SignFailed,
	AlreadySigned,
	MissingKey,
}
