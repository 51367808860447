import TextField from "@mui/material/TextField"
import React, { ChangeEvent } from "react"
import InputWrapper from "./InputWrapper"

interface TyperProps {
	label: string
	value: any
	onTyped: (value: string, callback?: () => void) => void
	required?: boolean
	setIsValid?: (isValid: boolean) => void
	regExValidation?: RegExp
	validator?: (val: string, isOnChange?: boolean) => boolean
	min?: number
	max?: number
	// validate?: (val: string, isOnChange?: boolean) => boolean
	placeholder?: string
	multiline?: boolean
	description?: string
	variant?: "outlined" | "filled" | "standard"
	type?: "text" | "password" | "number" | "date" | "datetime"
	style?: object
}

interface TyperState {
	isValid?: boolean
}

class Typer extends React.Component<TyperProps, TyperState> {
	constructor(props: TyperProps) {
		super(props)

		this.state = {
			isValid: undefined,
		}
	}

	componentDidUpdate = (prevProps: TyperProps) => {
		if (prevProps.value !== this.props.value) {
			this.validate(true)
		}
	}

	validate = (isOnChange: boolean = false) => {
		const {
			value,
			required,
			setIsValid,
			validator,
			regExValidation,
			type,
			min,
			max,
		} = this.props
		const {
			isValid,
		} = this.state

		let newValidState = true

		if (!value || !value.length) {
			if (required) {
				newValidState = false
			}
		}
		else {
			switch (type) {
				case "number":
					try {
						const numValue = parseFloat(value)
	
						if (isNaN(numValue)) {
							newValidState = false
						}
						else {
							if (min !== undefined && numValue < min) {
								newValidState = false
							}
		
							if (max !== undefined && numValue > max) {
								newValidState = false
							}
						}
					}
					catch (e) {
						newValidState = false
					}

					break
				default:
					if (regExValidation !== undefined) {
						newValidState = regExValidation.test(value)
					}

					if (min !== undefined && value.length < min) {
						newValidState = false
					}

					if (max !== undefined && value.length > max) {
						newValidState = false
					}

					break
			}

			if (validator !== undefined && newValidState) {
				newValidState = validator(value, isOnChange)
			}
		}

		if (isOnChange && isValid === undefined && !newValidState) {
			return
		}

		this.setState({
			isValid: newValidState,
		}, () => {
			if (setIsValid !== undefined) {
				setIsValid(newValidState)
			}
		})
	}

	onType = (value: string) => {
		const {
			onTyped,
			type,
		} = this.props

		switch (type) {
			case "number":
				if (isNaN(parseFloat(value))) {
					return
				}
				break
		}

		onTyped(value)
	}

	render() {
		const {
			label,
			value,
			onTyped,
			required,
			placeholder,
			multiline,
			description,
			variant,
			type,
			style,
		} = this.props
		const {
			isValid,
		} = this.state

		return (
			<InputWrapper
				// label={label}
				description={description}
				style={{
					margin: "6px 0",
					...style,
				}}
			>
				<TextField
					fullWidth
					required={required}
					// inputProps={{
					// 	// inputMode: 'numeric',
					// 	pattern: regExValidation,
					// }}
					type={type || "text"}
					label={label}
					InputLabelProps={{
						shrink: true,
					}}
					// inputProps={{
					// 	minLength: min,
					// 	maxLength: max,
					// }}
					value={value}
					error={isValid === false}
					multiline={multiline}
					onChange={(event: ChangeEvent<{ value: unknown }>) => {
						const val = event.target.value as string

						this.onType(val)
					}}
					onBlur={(event: ChangeEvent<{ value: unknown }>) => {
						this.validate()
					}}
					placeholder={placeholder}
					variant={variant || "standard"}
				/>
			</InputWrapper>
		)
	}
}

export default Typer
