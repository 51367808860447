import IClientDetails from "../models/IClientDetails"
import IClientQuestionnaire from "./models/IClientQuestionnaire"
import SignatureType from "./models/SignatureType"
import ISignatureResponse from "./models/ISignatureResponse"
import IClientSubmitAnswer from "./models/IClientSubmitAnswer"
import ClientBaseApi from "./ClientBaseApi"
import { RequestMethod } from "../RequestMethod"

export default class ClientQuestionnaireApi extends ClientBaseApi {
	protected static baseUri = "questionnaire"

	public static getOne(key: string) {
		return this.fetch<IClientQuestionnaire>(undefined, RequestMethod.GET, undefined, [ key ])
	}

	public static submit(
		key: string,
		signatureType: SignatureType,
		answers: IClientSubmitAnswer[],
		clientDetails?: IClientDetails,
		// clientPercievedTargetCase: string = "",
	) {
		const load = {
			signatureType,
			answers,
			clientDetails,
		}

		return this.fetch<ISignatureResponse>("submit", RequestMethod.POST, load, [ key ])
	}

	// THROWAWAY?
	// public static submitBankId() {
	// 	const load = {
	// 	}

	// 	return this.fetch<???>("submitbankid", RequestMethod.POST, load, [ ??? ])
	// }

	public static cancelSign(orderRef: string) {
		return this.fetch<string>("cancel-sign", RequestMethod.GET, undefined, [ orderRef ])
	}

	public static getQrCode(orderRef: string) {
		return this.fetch<string>("get-qr-code", RequestMethod.GET, undefined, [ orderRef ])
	}

	public static collectBankIdSignature(orderRef: string) {
		return this.fetch<boolean>("collect-signature", RequestMethod.GET, undefined, [ orderRef ])
	}
}
