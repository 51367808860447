import { UAParser } from "ua-parser-js";

export default class DeviceHelper {
	public static getDeviceInfo = () => {
		const uap = new UAParser()
		
		return uap.getResult()
	}

	public static isHandHeld = (): boolean => {
		const uap = new UAParser()
		const device = uap.getDevice().type

		return device !== undefined
			&& ["mobile", "tablet"].includes(device)
	}

	public static isAndroid = (): boolean => {
		const uap = new UAParser()
		const os = uap.getOS().name

		return os !== undefined
			&& os.toLowerCase().includes("android")
	}

	public static isIOS = (): boolean => {
		const uap = new UAParser()
		const os = uap.getOS().name

		return os !== undefined
			&& (os.toLowerCase().includes("ios")
				|| this.isHandHeld()
					&& os.toLowerCase().includes("mac"))
	}
}
