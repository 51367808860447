import React, { CSSProperties, ReactNode } from "react"
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material"
import AButton, { IAButtonProps } from "./AButton"

export interface IAPopProps {
	title: string
	show: boolean
	type?: "info" | "warning" | "error"
	onConfirm?: () => void
	onSubmit?: () => void
	onCancel?: () => void
	onClose?: (event: any, reason: "backdropClick" | "escapeKeyDown" | "closeButton") => void
	confirmLabel?: string
	submitLabel?: string
	cancelLabel?: string
	closeLabel?: string
	confirmDisabled?: boolean
	submitDisabled?: boolean
	customButtons?: IAButtonProps[]
	fullScreen?: boolean
	closeByButtonOnly?: boolean
	message?: string
	children?: ReactNode
	style?: CSSProperties
}

// TODO: let supers extend and fix with generic type props
export default class APop<P extends IAPopProps> extends React.Component<P> {
	constructor(props: P) {
		super(props)
	}

	renderTitle = () => {
		const {
			title,
			// ariaIdentifier,
		} = this.props

		return <DialogTitle
			// id={"dialog-" + ariaIdentifier + "-title"}
			style={{
				flex: 1,
				display: "flex",
				paddingTop: 14,
				paddingLeft: 12,
				paddingBottom: 10,
			}}
		>
			{title}
		</DialogTitle>
	}

	renderContent = () => {
		const {
			message,
			children,
		} = this.props

		return <DialogContent
			dividers
			style={{
				padding: 12,
				minWidth: 400,
			}}
		>
		{
			message !== undefined
			? <DialogContentText>
				{message}
			</DialogContentText>
			: undefined
		}
		{
			children
		}
		</DialogContent>
	}

	renderActions = () => {
		const {
			onConfirm,
			onSubmit,
			onCancel,
			onClose,
			confirmLabel,
			submitLabel,
			cancelLabel,
			closeLabel,
			confirmDisabled,
			submitDisabled,
			customButtons,
		} = this.props

		return <DialogActions>
		{
			onConfirm !== undefined
			? <AButton
				label={confirmLabel ?? "ok"}
				key={"button-confirm"}
				action={onConfirm}
				type="confirm"
				disabled={confirmDisabled}
			/>
			: undefined
		}
		{
			onSubmit !== undefined
			? <AButton
				label={submitLabel ?? "spara"}
				key={"button-submit"}
				action={(e) => {
					onSubmit()
					e.preventDefault()
				}}
				type="submit"
				disabled={submitDisabled}
			/>
			: undefined
		}
		{
			customButtons !== undefined
			? customButtons.map(buttonConf => {
				return <AButton
					{...buttonConf}
					key={"button-" + buttonConf.label.replace(" ", "")}
				/>
			})
			: undefined
		}
		{
			onCancel !== undefined
			? <AButton
				label={cancelLabel ?? "avbryt"}
				key={"button-cancel"}
				action={onCancel}
				type="cancel"
			/>
			: undefined
		}
		{
			onClose !== undefined
			? <AButton
				label={closeLabel ?? "stäng"}
				key={"button-close"}
				action={(e) => { onClose(e, "closeButton") }}
				type="close"
			/>
			: undefined
		}
		</DialogActions>
	}

	render() {
		const {
			show,
			onClose,
			fullScreen,
			closeByButtonOnly,
			style,
		} = this.props

		return <Dialog
			open={show}
			onClose={!closeByButtonOnly ? onClose : undefined}
			fullScreen={fullScreen}
			className="dialog"
			style={{...style}}
		>
		{
			this.renderTitle()
		}
		{
			this.renderContent()
		}
		{
			this.renderActions()
		}
		</Dialog>
	}
}
