import { Collapse } from "@mui/material"
import Alert from "@mui/material/Alert"
import React from "react"
import Typer from "../../../components/form/Typer"
import { UserRole } from "../../../api/bo/models/auth/UserRole"
import UserService from "../../../services/UserService"
import APopForm from "../../../components/APopForm"

interface CreateUserProps {
	onCreated: () => void
	onCancel: () => void
	showDialog: boolean
}
interface CreateUserState {
	username: string
	password: string
	role: UserRole
	error: string
}

class CreateUser extends React.Component<CreateUserProps, CreateUserState> {
	constructor(props: CreateUserProps) {
		super(props)

		this.state = {
			username: "",
			password: "",
			role: UserRole.STANDARD,
			error: "",
		}
	}

	createUser = () => {
		const {
			username,
			password,
			role,
		} = this.state
		const {
			onCreated,
		} = this.props

		UserService.create(username, password, role).then(success => {
			if (success) {
				this.setState({
					username: "",
					password: "",
					error: "",
				}, () => { onCreated() })
			}
		})
	}

	cancel = () => {
		const {
			onCancel,
		} = this.props

		this.setState({
			username: "",
			password: "",
			error: "",
		}, onCancel)
	}

	render() {
		const {
			username,
			password,
			error,
		} = this.state
		const {
			showDialog,
		} = this.props

		return (
			<APopForm
				title="Skapa användare"
				show={showDialog}
				onSubmit={this.createUser}
				onClose={this.cancel}
			>
				<Collapse in={error.length !> 0}>
					<Alert severity="error">{error}</Alert>
				</Collapse>
				<Typer
					value={username}
					required
					min={4}
					max={40}
					onTyped={(x => this.setState({ username: x }))}
					label="Användarnamn/epost"
				/>
				<Typer
					value={password}
					type="password"
					required
					min={8}
					max={40}
					onTyped={(x => this.setState({ password: x }))}
					label="Lösenord"
				/>
			</APopForm>
		)
	}
}

export default CreateUser
