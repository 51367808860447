import React from "react"
import {
	Typography,
} from "@mui/material"
import Lister from "../../../../components/lister/Lister";
import ManagePart from "./ManagePart";
import ICalculation from "../../../../api/bo/models/questionnaire/ICalculation";
import ICalculationPart from "../../../../api/bo/models/questionnaire/ICalculationPart";
import IQuestion from "../../../../api/bo/models/questionnaire/IQuestion";
import IQuestionnaire from "../../../../api/bo/models/questionnaire/IQuestionnaire";
import QuestionType from "../../../../api/bo/models/questionnaire/QuestionType";
import IReceiverType from "../../../../api/bo/models/receivertype/IReceiverType";

interface PartListProps {
	activePartIndex?: number;
	setActivePartIndex: (index?: number) => void;
	questionnaire?: IQuestionnaire;
	calculation?: ICalculation;
	receiverTypes: IReceiverType[];
	updatePart: (part: ICalculationPart) => void;
	deletePart: (partId: string) => void;
}

interface PartListState {
}

class PartList extends React.Component<PartListProps, PartListState> {
	constructor(props: PartListProps) {
		super(props)
	}

	getQuestionRanges = (item: ICalculationPart) => {
		const {
			questionnaire,
		} = this.props

		if (questionnaire === undefined
			|| !item.questionIds.length) {
			return []
		}

		const ranges: number[][] = [[]]
		const questions = questionnaire.questions.sort((a, b) => {
			return a.order - b.order
		})

		for (let i = 0; i < questions.length; i++) {
			const q = questions[i]

			if (item.questionIds.find(qi => q.questionId === qi)) {
				const latestRange = ranges[ranges.length - 1]
				const latestOrder = latestRange[latestRange.length - 1]
				
				if (latestOrder === undefined
					|| q.order === latestOrder + 1) {
					latestRange.push(q.order)

					continue
				}

				ranges.push([q.order])
			}
		}

		const rangesToPrint: string[] = []

		for (let i = 0; i < ranges.length; i++) {
			const range = ranges[i]

			if (range.length < 2) {
				for (let j = 0; j < range.length; j++) {
					rangesToPrint.push(range[j].toString())
				}

				continue
			}

			rangesToPrint.push(range[0].toString() + "-" + range[range.length - 1].toString())
		}

		return rangesToPrint
	}

	render() {
		const {
			questionnaire,
			calculation,
			receiverTypes,
			setActivePartIndex,
			activePartIndex,
			updatePart,
			deletePart,
		} = this.props

		if (!calculation) {
			return null
		}

		const activePart = activePartIndex !== undefined
			? calculation.parts[activePartIndex]
			: undefined

		const activePartQuestionIds: string[] = !activePart
			? []
			: activePart.questionIds

		return (
			<div
				style={{
					flex: 1,
					display: "flex",
					flexDirection: "row",
					height: "100%",
				}}
			>
				<div
					style={{
						flex: 0.8,
						display: "flex",
						flexFlow: "column",
						alignItems: "stretch",
						// height: "100%",
					}}
				>
					<Typography
						style={{
							margin: "8.5px 0",
							fontSize: "17px",
							lineHeight: 1,
							letterSpacing: 1.5,
							textTransform: "uppercase",
							color: "#111111",
						}}
					>
						regler
					</Typography>
				{
					calculation !== undefined
					? <Lister<ICalculationPart>
						items={calculation.parts}
						selectedKeys={
							activePartIndex !== undefined
							? [activePartIndex.toString()]
							: []
						}
						activeContentRenderer={(item, index) => {
							return <ManagePart
								questionnaire={questionnaire}
								receiverTypes={receiverTypes}
								part={item}
								updatePart={updatePart}
							/>
						}}
						keyExtractor={(item, index) => index.toString()}
						labelExtractor={(item, index) => {
							const ranges = this.getQuestionRanges(item)

							return item.identifier + (ranges.length ? " (" + ranges.join(", ") + ")" : "")
						}}
						onSelect={(parts, part, index) => {
							setActivePartIndex(index)
						}}
						onDelete={(item, index) => {
							if (!item.partId) {
								return
							}

							deletePart(item.partId)
						}}
						hideCheckbox
						singleSelect
						itemStyle={{
							margin: "4.25px 6.5px 4.25px 2px",
						}}
					/>
					: null
				}
				</div>
				<div
					style={{
						// height: "100%",
						flex: 0.20,
						display: "flex",
						// marginLeft: "17px",
						// margin: "8.5px 0",
						flexFlow: "column",
						alignItems: "stretch",
						// justifyItems: "stretch",
					}}
				>
					<div
						style={{
							flex: "none",
						}}
					>
						<Typography
							style={{
								margin: "8.5px 0",
								fontSize: "17px",
								lineHeight: 1,
								letterSpacing: 1.5,
								textTransform: "uppercase",
								color: "#111111",
							}}
						>
							Frågor
						</Typography>
					</div>
					<div
						style={{
							flex: 1,
							maxHeight: "calc(100% - 172px)",
							minHeight: "0px",
							overflowY: "scroll",
						}}
					>
						<Lister<IQuestion>
							items={questionnaire
								? questionnaire.questions
									.filter(q => q.type != QuestionType.FreeText)
									.sort((a, b) => a.order - b.order)
								: []}
							selectedKeys={activePartQuestionIds}
							keyExtractor={(item, index) => item.questionId!}
							labelExtractor={(item, index) => "#" + item.order}
							onSelect={(questions, question) => {
								if (!activePart) {
									return
								}

								updatePart({
									...activePart,
									questionIds: questions.map(q => q.questionId!),
								})
							}}
							disabled={activePartIndex === undefined}
							itemStyle={{
								margin: "4.25px 4.25px 4.25px 2px", // 8.5px 4.25px 4.25px",
							}}
						/>
					</div>
				</div>
			</div>
		)
	}
}

export default PartList