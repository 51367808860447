import IQuestionnaire from "./models/questionnaire/IQuestionnaire"
import ISimpleQuestionnaire from "./models/questionnaire/ISimpleQuestionnaire"
import AdminBaseApi from "./AdminBaseApi"
import { RequestMethod } from "../RequestMethod"

export default class QuestionnaireManagementApi extends AdminBaseApi {
	protected static baseUri = "questionnaire"

	public static getAll() {
		return this.fetch<ISimpleQuestionnaire[]>(undefined, RequestMethod.GET)
	}

	public static getOne(id: string) {
		return this.fetch<IQuestionnaire>(undefined, RequestMethod.GET, undefined, [ id ])
	}

	public static create(questionnaire: IQuestionnaire) {
		return this.fetch<string>(undefined, RequestMethod.POST, questionnaire)
	}

	public static update(id: string, questionnaire: IQuestionnaire) {
		return this.fetch<string>(undefined, RequestMethod.PUT, questionnaire, [ id ])
	}

	public static lock(id: string) {
		return this.fetch<string>("lock", RequestMethod.GET, undefined, [ id ])
	}
	
	public static delete(id: string) {
		return this.fetch<string>("delete", RequestMethod.GET, undefined, [ id ])
	}
}
